import React from 'react';
import { Platform } from 'react-native';
import { notificationService } from '../../../services/notification-service';
import * as Location from 'expo-location';

// Get IpAddress
export const getIpAddressFunc = async (): Promise<string | null> => {
    const appVersion = process.env.REACT_NATIVE_APP_VERSION;
    const reactNativeVersion = Platform.Version || "Unknown";
    const userAgent = `MeanderEvApp/${appVersion} (ReactNative/${reactNativeVersion})`;
    const host = "meanderev.com"; // Replace this with your actual API host
  
    try {
      const response = await fetch(`https://ipapi.co/json`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          "User-Agent": userAgent,
          //"Host": host,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        notificationService.sendNotification(
          "error",
          `Cannot retrieve IP Address`
        );
        return null;
      }
  
      const data = await response.json();
      //console.log("getting IP address", data);
  
      const IpSend = data.ip;
      return IpSend;
    } catch (e) {
      console.log("Error trying to retrieve IP address:", e);
      return null;
    }
};

export const getLocationFromIp = async (): Promise<Location.LocationObjectCoords> => {
  let myLocation: Location.LocationObjectCoords = {
    latitude: 39.7392, // Default to Denver, CO
    longitude: -104.9903,
    accuracy: 0,
    altitude: 0,
    altitudeAccuracy: 0,
    heading: 0,
    speed: 0,
  };
  try {
    const response = await fetch(`https://ipapi.co/json/`);
    const data = await response.json();
    if (data.latitude && data.longitude) {
      myLocation = {
        ...myLocation, // Keep the same structure
        latitude: data.latitude,
        longitude: data.longitude,
      };
    } else {
      console.error('Unable to fetch location from IP');
    }
  } catch (error) {
    console.error('Error fetching location from IP:', error);
  }

  return myLocation;
};