import * as Location from 'expo-location';
import {
    Platform
  } from "react-native";
import { notificationService } from '../../../services/notification-service';
import { getLocationFromIp } from './ip-address';

export const getUserLocation = async (): Promise<Location.LocationObjectCoords> => {
    let myLocation: Location.LocationObjectCoords = {
        latitude: 39.7392, // Default to Denver, CO
        longitude: -104.9903,
        accuracy: 0,
        altitude: 0,
        altitudeAccuracy: 0,
        heading: 0,
        speed: 0,
      };
      try {
        // Custom timeout for the permission request
        const requestWithTimeout = new Promise<{ status: string }>((resolve, reject) => {
          const timeout = setTimeout(() => reject(new Error('Permission request timeout')), 9000); // 9 seconds timeout
          Location.requestForegroundPermissionsAsync()
            .then((result) => {
              clearTimeout(timeout);
              resolve(result);
            })
            .catch((error) => {
              clearTimeout(timeout);
              reject(error);
            });
        });
    
        const { status } = await requestWithTimeout;
    
        if (status === 'granted') {
          const location = await Location.getCurrentPositionAsync({});
          myLocation = {
            ...myLocation,
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
            accuracy: location.coords.accuracy,
            altitude: location.coords.altitude,
            altitudeAccuracy: location.coords.altitudeAccuracy,
            heading: location.coords.heading,
            speed: location.coords.speed,
          };
        } else {
          myLocation = await getLocationFromIp(); // Fallback to IP-based location
        }
      } catch (error) {
        console.error('Error getting location:', error);
        //notificationService.sendNotification('error', `Error requesting location: ${error}`);
        // Fallback to IP-based location if permissions or request fails
        myLocation = await getLocationFromIp();
      }
    return myLocation;
};
