import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const offsetToTimezone: { [key: string]: string } = {
    '600': 'HST', // Hawaii-Aleutian Standard Time (UTC-10)
    '540': 'AKST', // Alaska Standard Time (UTC-9)
    '480': 'PST', // Pacific Standard Time (UTC-8)
    '420': 'MST', // Mountain Standard Time (UTC-7)
    '360': 'CST', // Central Standard Time (UTC-6)
    '300': 'EST', // Eastern Standard Time (UTC-5)
    '240': 'AST', // Atlantic Standard Time (UTC-4)
    '210': 'NST', // Newfoundland Standard Time (UTC-3:30)
     '0': 'UTC',   // Coordinated Universal Time (UTC+0)
     '-60': 'CET',  // Central European Time (UTC+1)
    '-120': 'EET',  // Eastern European Time (UTC+2)
    '-180': 'MSK',  // Moscow Standard Time (UTC+3)
  };

export const getCurrentUtcSeconds = (): number => {
    const currentDate = new Date();
    const utcSeconds = Math.floor(currentDate.getTime() / 1000);
    return utcSeconds;
}

export const getPastUtcSeconds = (rollBackSeconds: number): number => {
    //rollback the current time by a number of seconds
    const currentDate: Date = new Date();
    const rollbackDate: Date = new Date(currentDate.getTime() - (rollBackSeconds * 1000));

    const utcSeconds: number = Math.floor(rollbackDate.getTime() / 1000);

    return utcSeconds;
}

export const getDateInUtcSeconds = (d:Date): number => {
    const utcSeconds = Math.floor(d.getTime() / 1000);
    return utcSeconds;
}

export const getDateInLocalSeconds = (d:Date, tzOffset: number): number => {
    const tzOffsetSeconds = (tzOffset ?? 0) * 60; //offset time comes in minutes, convert to seconds
    const localSeconds = Math.floor(d.getTime() / 1000) - tzOffsetSeconds;
    return localSeconds
}

export const getDateFromUtcSeconds = (utcSeconds: number): Date => {
    const milliseconds = utcSeconds * 1000;
    return new Date(milliseconds);
}

export const getDateFromLocalSeconds = (utcSeconds: number, tzOffset: number): Date => {
    const milliseconds = utcSeconds * 1000;
    const tzOffsetmilliseconds = (tzOffset ?? 0) * 60 * 1000;
    return new Date(milliseconds + tzOffsetmilliseconds);
}

// FUNCTION: getDateString
// PURPOSE: Return date in MM/DD/YYYY format
export const getDateString = (d: Date): string => {
    const pad = (n: number) => n < 10 ? '0' + n : n;
    const month = pad(d.getMonth() + 1); // getMonth() is zero-based
    const day = pad(d.getDate());
    const year = d.getFullYear();
    const formattedDate = `${month}/${day}/${year}`
    return formattedDate;
}

//FUNCTION: getTimeString
// PURPOSE: return time in HH:MM:SS format
export const getTimeString = (d: Date): string => {
    const pad = (n: number) => n < 10 ? '0' + n : n;

    let hours = d.getHours();
    const minutes = pad(d.getMinutes());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedTime = `${pad(hours)}:${minutes}${ampm}`;
    return formattedTime;
};

export const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
};

export const getOneMonthBackDate = () => {
    const today = new Date();
    const oneMonthBack = new Date(today.setMonth(today.getMonth() - 1));
    const dd = String(oneMonthBack.getDate()).padStart(2, '0');
    const mm = String(oneMonthBack.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = oneMonthBack.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
};

// Format the date to mm/dd/yyyy
// Input format is 2024-05-20T21:46:52.000Z
export const formattedDate = (inputDateTimeStr: string | null) => {
    if (inputDateTimeStr !== null){
        const inputDate = new Date(inputDateTimeStr);
        return(
            inputDate.toLocaleDateString('en-US', 
                {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
            )
    }
}

// Format the time to HH:MM
// Input format is 2024-05-20T21:46:52.000Z
export const formattedTimeNoSeconds = (
    inputDateTimeStr: string | null,
    tzOffset: number,
) => {

    if (!inputDateTimeStr) return null; // Handle null input

    const guessTimezone = (tzOffset: string) => {
        const timezoneName = offsetToTimezone[tzOffset];
        return timezoneName || 'UTC'; // Default to UTC if no match
    };

    // Step 1: Parse the input time as UTC
    const inputDateTime = dayjs.utc(inputDateTimeStr);

    // Step 2: Convert offset to timezone
    const tzName = guessTimezone(tzOffset.toString()) // Fallback timezone if needed
    const adjustedTime = inputDateTime.utcOffset(-tzOffset); // Adjust by offset

    // Step 3: Format time with timezone information
    const formattedTime = adjustedTime.format('HH:mm') + ` ${tzName}`;
    //const timezoneOffset = tzOffset > 0 ? `+${-tzOffset / 60}` : `${-tzOffset / 60}`;
    return formattedTime;
}

export const secondsToHHMMSS = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
  
    // Pad the values with leading zeros if necessary
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(secs).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}.${formattedSeconds}`;
  }