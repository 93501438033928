import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  SafeAreaView,
  Dimensions,
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Pressable,
  Platform,
  Linking,
  ActivityIndicator,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome6 } from "@expo/vector-icons";
import { ChargerAttributes } from "../models/charger-model";
import { AppDispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyDistanceFromCharger,
  getMyDrivingTime,
  updateSelectedCharger,
} from "../store/actions/charging-actions";
import * as Location from "expo-location";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../components/MeanderStack";
import SignInOptions from "./SignInOptions";
import { OrderAttributes } from "../models/order-model";
import { UserSessionAttributes } from "../models/user-session-model";
import { getUserLocation } from "../components/common/utils/get-user-location";
import CreateAccountForm from "./CreateAccountForm";

import { notificationService } from "../services/notification-service";
import {
  cancelOrder,
  createOrder,
  updateOrderEmail,
} from "../services/orders-service";
import { checkoutCharger } from "../services/charger-service";
import {
  loadStripe,
  Stripe,
  StripeAddressElement,
  StripeElements,
  StripePaymentElement,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { isSuccessful } from "../services/api-requests";
import { getPaymentDetails } from "../services/payments-service";
import { PaymentDetails } from "../models/payment-model";
import StripeSubmitButton from "../components/payments/StripeSubmitButton";
import LoadingButton from "../components/LoadingButton";
import getDirections from "../components/common/utils/get-directions";
import { getLocationFromIp } from "../components/common/utils/ip-address";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import DirectionsButton from "../components/DirectionsButton";
import { ActiveChargeAttributes } from "../models/active-charges-model";

const { width, height } = Dimensions.get("window");

type ChargerStationNavigationProps = NativeStackScreenProps<
  RootStackParamList,
  "ChargerStation"
>;

export const ChargerStation: React.FC<ChargerStationNavigationProps> = ({
  navigation,
  route,
}) => {
  const [email, setEmail] = useState<string | null>(null);
  const [guestEmail, setGuestEmail] = useState<string | null>(null);
  const [storedEmail, setStoredEmail] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [myDistanceFromCharger, setMyDistanceFromCharger] =
    React.useState<string>("");
  const [myTimeFromCharger, setMyTimeFromCharger] = React.useState<string>("");
  const [myLocation, setMyLocation] =
    React.useState<Location.LocationObjectCoords | null>(route.params.myLocation ?? null);
  const [paymentData, setPaymentData] = React.useState<PaymentDetails | null>(
    null
  );
  const [checkoutButtonDisabled, setCheckoutButtonDisabled] =
    React.useState<Boolean>(true);
  const [order, setOrder] = React.useState<OrderAttributes | null>(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [paymentsDropdownOpen, setPaymentsDropdownOpen] =
    React.useState<boolean>(false);
  const [stripe, setStripe] = React.useState<Stripe | null>();
  const [stripeElements, setStripeElements] = React.useState<
    StripeElements | undefined
  >();
  const [stripePaymentElement, setStripePaymentElement] = React.useState<
    StripePaymentElement | undefined
  >(undefined);
  const [stripeAddressElement, setStripeAddressElement] = React.useState<
    StripeAddressElement | undefined
  >(undefined);
  const [loadError, setLoadError] = React.useState<boolean>(false)
  const activeCharges: ActiveChargeAttributes[] | null = useSelector(
      (state: RootState) => state.user.activeCharges
    );

  const dispatch: AppDispatch = useDispatch();

  const session: UserSessionAttributes | undefined = useSelector(
    (state: RootState) => state.user.session
  );

  const scrollViewRef = useRef<ScrollView>(null);

  const selectedCharger: ChargerAttributes = useSelector(
    (state: RootState) => state.chargers.selectedCharger
  );

  const renderCharger = useMemo(() => {
    return selectedCharger;
  }, [selectedCharger]);

  const onDropdownPressed = () => {
    if (paymentsDropdownOpen) {
      // Unmount
      setPaymentsDropdownOpen(false);
      stripePaymentElement?.unmount();
      stripeAddressElement?.unmount();
    } else {
      // Mount
      stripePaymentElement?.mount("#payment-element");
      stripeAddressElement?.mount("#address-element");
      setPaymentsDropdownOpen(true);
    }
  };

  const onPaymentSubmit = async (): Promise<void> => {
    try {
      const confirmation = await stripe?.confirmPayment({
        elements: stripeElements,
        clientSecret: order?.payment?.clientSecret || "",
        redirect: "if_required",
        confirmParams: {},
      });
  
      // Handle payment confirmation status
      switch (confirmation?.paymentIntent?.status) {
        case "requires_capture": {
          const paymentDetailsResponse = await getPaymentDetails(order?.id!);
  
          // Update payment data and UI state
          setPaymentData(paymentDetailsResponse.payload!);
          setCheckoutButtonDisabled(false);
          setPaymentsDropdownOpen(false);
          stripePaymentElement?.unmount?.();
          stripeAddressElement?.unmount?.();
          break;
        }
        default:
          console.log("Unhandled payment status:", confirmation?.paymentIntent?.status);
      }
      console.log("Payment confirmation:", confirmation);
    } catch (error) {
      console.error("Error during payment submission:", error);
    }
  };

  const handleRemovePayment = async () => {
    setCheckoutButtonDisabled(true);
    setOrder(null);
    setPaymentData(null);
    cancelOrder(order!.id).then(async (response) => {
      const order = await createOrderAndGetOrder();
      if (!order) {
        setLoadError(true)
      }
      setOrder(order);
      onDropdownPressed();
    });
  };

  const createOrderAndGetOrder = async (): Promise<OrderAttributes | null> => {
    if (selectedCharger.uuid) {
      const orderResponse = await createOrder(selectedCharger.uuid);
      if (isSuccessful(orderResponse.status)) {
        return orderResponse.payload;
      } else {
        return null;
      }
    } else {
      // TODO: Send a toast complaint probably
      return null;
    }
  };

  useEffect(() => {
    setEmail(guestEmail);
  }, [guestEmail]);

  const handleCheckoutButton = async () => {
    if (checkoutButtonDisabled) {
      return;
    }
    setCheckoutButtonDisabled(true);
    if (session === undefined) {
      notificationService.sendNotification("error", "Invalid Session");
      setCheckoutButtonDisabled(false);
      return;
    } else if (email === null) {
      notificationService.sendNotification("error", "Please enter your email");
      setCheckoutButtonDisabled(false);
      return;
    } else if (!order) {
      notificationService.sendNotification(
        "error",
        "Problem creating your order"
      );
      setCheckoutButtonDisabled(false);
      return;
    }

    try {
      // Set the order email
      await updateOrderEmail(order?.id, email);

      // Perform checkout logic
      const checkoutResponse = await checkoutCharger(
        order?.id!,
        session.role === "guest" ? email : session.email
      );

      if (isSuccessful(checkoutResponse.status)) {
        // Update the charger object if necessary
        if (selectedCharger.uuid) {
          dispatch(updateSelectedCharger(selectedCharger.uuid));
          // Optionally update the charger list here
        }

        // Navigate to the ChargingScreen
        navigation.navigate("ChargingScreen", { order });
      } else {
        notificationService.sendNotification(
          "error",
          "Error checking out charger."
        );
      }
    } catch (e) {
      // Handle error
      console.error(e);
      notificationService.sendNotification(
        "error",
        `Error checking out charger: ${e}`
      );
    }
    setCheckoutButtonDisabled(false);
  };

  useEffect(() => {
    //grab the user info
    const getStorageInfo = async () => {
      const userEmail = await AsyncStorage.getItem("email");
      setStoredEmail(userEmail);
    };

    const fetchDistance = async () => {
      let getLocation;
      if(!myLocation){
        getLocation = await getLocationFromIp();
        setMyLocation(getLocation);
      } else {
        getLocation = myLocation;
      }
      const myDistance = getMyDistanceFromCharger(
        getLocation,
        renderCharger
      );
      setMyDistanceFromCharger(myDistance.toString());
    };

    const fetchDrivingTime = async () => {
      let getLocation;
      if(!myLocation){
        getLocation = await getLocationFromIp();
        setMyLocation(getLocation);
      } else {
        getLocation = myLocation;
      }
      if(renderCharger.uuid){
        const myTime = await getMyDrivingTime(getLocation, renderCharger.uuid);
        setMyTimeFromCharger(myTime);
      }
    };

    const requestLocation = async () => {
      const userLocation = await getUserLocation();
      setMyLocation(userLocation);
    };

    const fetchStripe = async () => {
      const loadedStripe = await loadStripe(
        process.env.STRIPE_PUBLISHABLE_KEY!
      );
      return loadedStripe;
    };

    requestLocation();
    getStorageInfo();

    if (route?.params.searchCharger === undefined) {
      setMyDistanceFromCharger(route.params.myDistanceFromCharger!);
      setMyTimeFromCharger(route.params.myTimeFromCharger!);

      if (!route.params.myDistanceFromCharger) {
        fetchDistance();
      }
      if (!route.params.myTimeFromCharger) {
        fetchDrivingTime();
      }
    }

    Promise.all([createOrderAndGetOrder(), fetchStripe()]).then((values) => {
      if (values[0] === null) {
        setLoadError(true)
        return;
      }
      setOrder(values[0]);
      setStripe(values[1]);
      var elements =
        values[1]?.elements({
          clientSecret: values[0]?.payment.clientSecret,
          customerSessionClientSecret:
            values[0]?.payment.customerSessionClientSecret,
          appearance: {
            theme: "night",
            variables: {
              colorPrimary: "#3FC67D",
              colorBackground: "#E8E8E9",
              colorText: "#000000",
              colorTextSecondary: "#808080",
              colorTextPlaceholder: "#808080",
              colorDanger: "#ff0000",
            },
            labels: "floating",
            rules: {
              ".CheckboxLabel": {
                color: "#808080", // Specifically targets the "Save Payment Details" label
              },
            },
          },
        }) ?? undefined;
      setStripeElements(elements);
      const options: StripePaymentElementOptions = {};
      const paymentElement = elements?.create("payment", options);

      setStripePaymentElement(paymentElement);

      const addressElement = elements?.create("address", {
        mode: "billing",
        allowedCountries: ["US"],
      });
      setStripeAddressElement(addressElement);
    }).catch(error => {
      console.error("Failed to load stripe and/or order: " + error)
      setLoadError(true)
    });

    if (session) {
      setEmail(session?.email);
    }
  }, []);

  useEffect(() => {
    //there is something in session now, update the page states
    const getStorageInfo = async () => {
      const userEmail = await AsyncStorage.getItem("email");
      setStoredEmail(userEmail);
      setEmail(userEmail);
      // if(storedEmail === 'guest'){
      //   setStoredEmail(null);
      // } else {
      //   setStoredEmail(userEmail);
      // }
    };
    getStorageInfo();
  }, [session]);

  // Use useEffect to add the navigation listener
  useEffect(() => {
    const currentScreenName = route.name;

    // If the current screen is not "ChargerStation", unsubscribe right away
    if (currentScreenName !== "ChargerStation") {
      return;
    }

    const unsubscribe = navigation.addListener("blur", async (e) => {
      const navState = navigation.getState();

      // Check if the user is navigating and try to retrieve the next route name safely
      // Check if we can get the next route from the state
      const nextRoute = navState?.routes[navState.index];
      const nextRouteName = nextRoute?.name;

      // If the next route is neither ChargerStation nor ChargingScreen and there is an order
      if (
        order &&
        nextRouteName !== "ChargerStation" &&
        nextRouteName !== "ChargingScreen"
      ) {
        // Prevent the default navigation

        // cancel the order
        cancelOrder(order.id);
      }
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [navigation, route.name, order]);

  useEffect(() => {
    if (showCreateAccount) {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollToEnd({ animated: true });
      }
    }
  }, [showCreateAccount]); // Add dependencies as needed

  const handleOptionSelect = (value) => {
    setSelectedOption(value === selectedOption ? "" : value); // Toggle selection
  };

  const handleCreateAccount = (): void => {
    setShowCreateAccount(!showCreateAccount);
  };

  // Email validation function
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
    return emailRegex.test(email);
  };

  // Handler for email input change
  const handleEmailChange = (email: string) => {
    setGuestEmail(email);

    if (email.length > 0) {
      setIsValidEmail(validateEmail(email)); // Validate email as user types
    } else {
      setIsValidEmail(false); // Reset validation when input is empty
    }
  };

  const refRBSheet = useRef();
  const OpenBottomSheet = () => {
    refRBSheet.current.open();
  };

  const handleSignIn = (): void => {
    // use the signin popup
    // TODO: make sure to save this screen and come back to it!
    OpenBottomSheet();
  };

  

  return (
    <SafeAreaView style={styles.safeArea}>
      <View
        style={[
          styles.containerBgColor,
          { height: height - 65, paddingBottom: 10, flex: 1 },
        ]}
      >
        <ScrollView ref={scrollViewRef}>
          <Image
            source={require("../../assets/ChargerStationBg.png")}
            style={{ aspectRatio: 1 }}
            alt="Background Image"
          />
          <View className="mx-4">
            {renderCharger.isOccupied === 1 ? (
              <Text
                className="px-5 py-2 text-xs font-bold text-white rounded-full"
                style={[
                  styles.BgColorNotAvailable,
                  {
                    width: width <= 430 ? width * 0.3 : 100,
                    textAlign: "center",
                  },
                ]}
              >
                Not Available
              </Text>
            ) : (
              <Text
                className="px-5 py-2 text-xs font-bold text-white rounded-full"
                style={[
                  styles.BgColor1,
                  {
                    width: width <= 430 ? width * 0.3 : 100,
                    textAlign: "center",
                  },
                ]}
              >
                Available
              </Text>
            )}
            <Text className="py-2 text-2xl font-bold text-white">
              {renderCharger.name}
            </Text>
            <Text className="text-base font-normal text-white">
              {renderCharger.city}, {renderCharger.state}
            </Text>
            <Text
              className="py-2 text-xs font-normal text-white"
              style={styles.textColor}
            >
              {renderCharger.full_address}
            </Text>
            <View className="flex flex-row items-center justify-between">
              <View className="flex flex-row gap-3">
                <Image
                  source={require("../../assets/LocationMarker.png")}
                  style={{ height: 19, width: 19 }}
                  alt="Makers"
                />
                <Text className="text-white">
                  {myDistanceFromCharger !== undefined
                    ? `${myDistanceFromCharger} miles`
                    : <LoadingPlaceholder />
                  }
                </Text>
                <Image
                  source={require("../../assets/DrivingCar.png")}
                  style={{ height: 19, width: 19 }}
                  alt="Makers"
                />
                <Text className="text-white">
                  {myTimeFromCharger !== undefined
                    ? `${myTimeFromCharger}`
                    : <LoadingPlaceholder />
                  }
                </Text>
              </View>
              <View>
                <DirectionsButton
                  selectedCharger={selectedCharger}
                  buttonStyle={{
                    paddingHorizontal: 20,
                    paddingVertical: 8,
                    borderRadius: 20,
                    backgroundColor: styles.BgColor2.backgroundColor,
                  }}
                  textStyle={{
                    fontSize: 14,
                    fontWeight: '600',
                    color: '#fff',
                  }}
                  webClassName="px-5 py-2 text-xs font-semibold text-white rounded-full"
                />
              </View>
            </View>
            <View style={styles.line} />
            <View style={styles.BgColor3} className="p-3 rounded-lg">
              <Text className="text-base font-semibold text-white">
                Charger
              </Text>
              <View className="flex flex-row items-center gap-4 py-2">
                <Image
                  source={require("../../assets/chargerType.png")}
                  style={{ height: 40, width: 40 }}
                  alt="ChargerType"
                />
                <View>
                  <Text className="text-xs font-normal text-white">
                    {`${renderCharger.manufacturer_name}-${
                      renderCharger?.charger_type
                    } ${renderCharger?.plug_type ?? ""} Charger`}
                  </Text>
                  <View className="flex flex-row items-center gap-2">
                    <Text className="text-base font-semibold text-white">
                      {renderCharger.charging_capacity}kW
                    </Text>
                    <Text className="text-xs font-normal text-white">
                      Max power
                    </Text>
                  </View>
                </View>
              </View>
              <View className="flex flex-row justify-between">
                <Text className="text-base font-semibold text-white">
                  Peak rate
                </Text>
                <Text className="text-base font-semibold text-white">{`$${renderCharger.price_kwh_peak}/kWh`}</Text>
              </View>
              <View className="flex flex-row justify-between">
                <Text className="text-base font-semibold text-white">
                  Off-Peak rate
                </Text>
                <Text className="text-base font-semibold text-white">{`$${renderCharger.price_kwh_off_peak}/kWh`}</Text>
              </View>
            </View>
            <View style={styles.lin2} className="py-1" />
            {renderCharger.isOccupied === 0 && !activeCharges && order ? (
              <View className="flex flex-col">
                <Text className="py-2 text-2xl font-bold text-white">
                  Payment method
                </Text>
                <Pressable
                  className="flex flex-row items-center justify-between my-5"
                  onPress={() => onDropdownPressed()}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  disabled={paymentData !== null && paymentData !== undefined}
                >
                  <Image
                    source={require("../../assets/creditCardIcon.png")}
                    style={{ height: 34, width: 50 }}
                    alt="ChargerType"
                  />
                  <View className="flex flex-col items-start justify-between">
                    <Text
                      className="text-lg font-semibold text-white"
                      style={{ textAlign: "center", flex: 1 }}
                    >
                      {paymentData
                        ? "Change payment method"
                        : "Add a payment method"}
                    </Text>
                    {paymentData && (
                      <Text className="text-base text-slate-400">
                        Card ending in
                        {` ${paymentData.lastFour}`}
                      </Text>
                    )}
                  </View>

                  {paymentData && (
                    <Pressable
                      onPress={() => handleRemovePayment()}
                      //disabled={false}
                      // TODO: Collapse when we click outside of the payment methods view?
                      //onBlur={ () => closePaymentDropin() }
                    >
                      <MaterialIcons name="close" size={24} color="white" />
                    </Pressable>
                  )}
                  {!paymentData && (
                    <MaterialIcons
                      name="keyboard-arrow-right"
                      size={24}
                      color="white"
                    />
                  )}
                </Pressable>

                <View
                  id="address-element"
                  style={{ display: paymentsDropdownOpen ? 'flex' : 'none' }}
                  className="z-40 flex flex-col my-5"
                />
                <View
                  id="payment-element"
                  style={{ display: paymentsDropdownOpen ? 'flex' : 'none' }}
                  className="z-10 flex flex-col my-5"
                />
              </View>
            ) : (
              !activeCharges ? (
                <View style={styles.loadingContainer}>
                  <ActivityIndicator size="large" color="#39BEB7" />
                </View>
              ) : null
            )}
            {paymentsDropdownOpen && !activeCharges && (
              <StripeSubmitButton
                onSubmit={async () => {
                  //stripeElements?.submit().then(onPaymentSubmit);
                    if (stripeElements) {
                      try {
                        const result = await stripeElements.submit(); // Await stripeElements.submit()
                        await onPaymentSubmit(); // Await onPaymentSubmit()
                      } catch (error) {
                        console.error("Error during submission:", error);
                      }
                    }
                  }}
              />
            )}
            {loadError && !order && !selectedCharger.isOccupied && (
              <View
                className="flex flex-row items-center justify-start"
              >
                {/* <Image
                  source={require("../../assets/meander-error.png")}
                  style={{ height: 34, width: 50 }}
                /> */}
                <MaterialIcons name="close" size={50} color="#AA1111" />


                <View className="flex flex-col items-center justify-center">
                  <Text
                    className="mx-16 text-lg font-semibold text-center text-white"
                  >
                    Something went wrong
                  </Text>
                  <Text className="mx-16 text-base text-center text-slate-400">
                    Please refresh the page
                  </Text>
                </View>
              </View>
            )}
            {order && <View style={styles.lin2} className="py-1" />}
            {!selectedCharger.isOccupied && !activeCharges &&
              order &&
              (!storedEmail || session?.role === "guest") &&
              !showCreateAccount && (
                <View>
                  <Text className="py-2 text-2xl font-bold text-white">
                    Email
                  </Text>
                  <Text
                    className="py-2 text-xs font-medium text-zinc-400"
                    style={styles.textColor1}
                  >
                    Enter your email to receive a receipt after charging
                  </Text>
                  <TextInput
                    placeholder="Please enter your email"
                    placeholderTextColor="#808080"
                    value={guestEmail ?? ""}
                    onChangeText={handleEmailChange}
                    style={[
                      styles.BgColor6,
                      isValidEmail ? styles.valid : styles.invalid, // Conditionally change style based on validation
                    ]}
                    className="px-3 py-2 m-1 text-lg rounded-full"
                  />
                  {!isValidEmail && guestEmail !== "" && (
                    <Text style={styles.errorText}>
                      Please enter a valid email address.
                    </Text>
                  )}
                </View>
              )}
            {!selectedCharger.isOccupied && !activeCharges &&
              order &&
              (!storedEmail || session?.role === "guest") && (
                <View>
                  {/* <Pressable onPress={() => handleOptionSelect('option2')}> */}
                  <Pressable
                    onPress={() => {
                      handleCreateAccount();
                      handleOptionSelect("checked");
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                      className="mt-2 ml-2"
                    >
                      <View style={styles.checkBox}>
                        {selectedOption === "checked" && (
                          <View style={{ width: 17, height: 17 }}>
                            <FontAwesome6
                              name="check"
                              size={17}
                              color="black"
                            />
                          </View>
                        )}
                      </View>
                      <Text
                        className="text-xs font-medium"
                        style={styles.textColor2}
                      >
                        Create an account to check out faster next time
                      </Text>
                    </View>
                  </Pressable>
                </View>
              )}
            {!selectedCharger.isOccupied && !activeCharges &&
              order &&
              (!storedEmail || session?.role === "guest") && (
                <Pressable onPress={handleSignIn}>
                  <View className="flex flex-row items-center justify-center gap-2 mt-2 mb-4">
                    <Text className="text-xs font-medium text-white">{`Already have an account?`}</Text>
                    <Text
                      className="text-xs font-medium"
                      style={styles.textColorTeal}
                    >{` Sign in`}</Text>
                    <MaterialIcons
                      name="keyboard-arrow-right"
                      size={16}
                      color="white"
                    />
                  </View>
                </Pressable>
              )}
            {!selectedCharger.isOccupied && showCreateAccount && order && (
              <CreateAccountForm
                formEmail={guestEmail || ""}
                handleCreateAccount={handleCreateAccount}
              />
            )}
          </View>
          {!selectedCharger.isOccupied && order && !activeCharges && (
            <View style={styles.BgColor4} className="p-6">
              <Text className="text-base font-bold text-center text-slate-100">
                Complete checking out this charger
              </Text>
              <Text className="text-base font-normal text-center text-slate-100">
                By pressing “Check Out Charger” you agree to this charger’s
                hourly rate above.
              </Text>
              <Text className="text-base font-bold text-center text-slate-100">
                NOTE: You will be charged a minimum of $0.50 for this charge.
              </Text>
              <View style={{ marginTop: 10 }}>
                <LoadingButton
                  onPress={async () => {
                    await handleCheckoutButton(); // Ensure this function returns a promise
                  }}
                  disabled={!paymentData || (session?.role === 'guest' && !isValidEmail)}
                >
                    Complete Checkout
                </LoadingButton>
              </View>
            </View>
          )}
        </ScrollView>
        <SignInOptions
          navigation={navigation}
          refRBSheet={refRBSheet}
          destination={"ChargerStation"}
          orderId={order?.id}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#0B1E1D",
  },
  containerBgColor: {
    backgroundColor: "#0B1E1D",
  },
  BgColor1: {
    backgroundColor: "#51B868",
  },
  BgColor2: {
    backgroundColor: "#39BEB780",
  },
  BgColor3: {
    backgroundColor: "#203D3C",
  },
  BgColor4: {
    backgroundColor: "#003547",
  },
  BgColor5: {
    backgroundColor: "#3FC67D",
  },
  BgColor6: {
    backgroundColor: "#E8E8E9",
  },
  BgColor7: {
    backgroundColor: "#4F7F8B",
  },
  BgColorNotAvailable: {
    backgroundColor: "#4F7F8B",
  },
  valid: {
    borderColor: "green",
  },
  invalid: {
    borderColor: "red",
  },
  textColor: {
    color: "#8D8E90",
  },
  textColor1: {
    color: "#D1D2D3",
  },
  textColor2: {
    color: "#A3A5A6",
  },
  textColorTeal: {
    color: "#39BEB7",
  },
  line: {
    borderBottomColor: "#438b88",
    borderBottomWidth: 1,
    borderStyle: "dashed",
    marginVertical: 10,
  },
  lin2: {
    borderBottomColor: "#438b88",
    borderBottomWidth: 1,
    marginVertical: 10,
  },
  checkBox: {
    width: 19,
    height: 19,
    borderWidth: 2,
    marginRight: 8,
    borderColor: "#E8E8E9",
    backgroundColor: "#E8E8E9",
    borderRadius: 4,
  },
  paymentBox: {
    margin: 10,
  },
  createPasswordIcon: {
    position: "absolute",
    top: 45,
    alignSelf: "flex-end",
  },
  createPasswordIconConfirm: {
    position: "absolute",
    alignSelf: "flex-end",
  },
  errorText: {
    color: "red",
    fontSize: 12,
    marginTop: 4,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#0B1E1D", // Light background for better contrast
  },
});

export default ChargerStation;
