import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  Image,
  Dimensions,
  StyleSheet,
  TextInput,
  ScrollView,
  Pressable,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { getManufacturerInfoForCharger } from "../services/charger-service";
import { notificationService } from "../services/notification-service";
import DropdownComponent from "../components/DropdownComponent";
import { isSuccessful } from "../services/api-requests";
import { RootStackParamList } from "../components/MeanderStack";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

const { width, height } = Dimensions.get("window");

type ChargerValidationNavigationProps = NativeStackScreenProps<
  RootStackParamList,
  "ChargerValidation"
>;

const ChargerValidation: React.FC<ChargerValidationNavigationProps> = ({
  navigation,
  route,
}) => {
  const [serial, setSerial] = useState("");

  //validate charger before moving to the next step
  const validateSerial = async () => {
    const infoResponse = await getManufacturerInfoForCharger(serial);
    console.log(infoResponse);
    if (!isSuccessful(infoResponse.status)) {
      notificationService.sendNotification("error", "Charger not Validated");
    // const checkValidity = await validateCharger(serial);
    // console.log(checkValidity);
    // if(checkValidity.errStatus === 500){
    //   notificationService.sendNotification("error", "Not a valid serial number");
    // } else if (checkValidity.message === 'Charger is already registered!') {
    //   notificationService.sendNotification("error", checkValidity.message);
    } else {
      navigation.navigate("AddModifyCharger", {
        unit_serial: serial,
        capacity: infoResponse.payload!.charge_rate_amps,
        name: infoResponse.payload?.name,
        manufacturer: infoResponse.payload?.manufacturer
      });
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={[styles.containerBgColor, { flex: 1 }]}>
        <ScrollView contentContainerStyle={{ paddingBottom: 20 }}>
          <Image
            source={require("../../assets/ChargerStationBg.png")}
            style={{ aspectRatio: 1 }}
            alt="Background Image"
          />
          <View className="p-6">
            <Text className="text-lg font-semibold text-white">
              My Chargers
            </Text>
            <Text className="text-sm font-medium text-white">
              Register your first charger by entering the charger’s serial
              number below:
            </Text>
            <View className="flex flex-row items-center gap-4 p-6">
              <View>
                <Image
                  source={require("../../assets/stationPlugIn.png")}
                  resizeMode="cover"
                  style={{
                    height: 54,
                    width: 42,
                  }}
                />
              </View>
              <View>
                <Text className="text-base font-semibold text-white">
                  Serial Number
                </Text>
                <TextInput
                  placeholder="Enter serial number"
                  placeholderTextColor={"#fff"}
                  style={{ backgroundColor: "#292D33" }}
                  className="p-3 text-white rounded-full"
                  value={serial}
                  onChange={(e) => setSerial(e.nativeEvent.text)}
                />
              </View>
            </View>
            <Pressable
              style={{ backgroundColor: serial === "" ? "#636363" : "#39BEB7" }}
              className="p-3 mt-4 rounded-full"
              onPress={() => validateSerial()}
            >
              <Text className="text-center text-white">
                Continue to Registration
              </Text>
            </Pressable>

            <View>
              <View style={styles.line} />
              <View className="flex flex-row items-center gap-2">
                <AntDesign name="questioncircle" size={17} color="white" />
                <Text className="text-sm font-bold text-white">
                  Where can I find my charger’s serial number?
                </Text>
              </View>
              <Text className="mt-2 text-sm font-medium text-white">
                Your charger’s serial number is located on one of the sides or
                on the front of your charger.
              </Text>
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  containerBgColor: {
    backgroundColor: "#0B1E1D",
  },
  line: {
    borderBottomColor: "#636363",
    borderBottomWidth: 1,
    borderStyle: "dashed",
    marginVertical: 20,
  },
});

export default ChargerValidation