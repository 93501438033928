import React, {useEffect, useRef, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    Modal,
    ScrollView,
    Pressable,
    TouchableWithoutFeedback,
    Keyboard,
  } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import DatePicker from "../../components/DateTimePicker";
import { getCurrentDate, getOneMonthBackDate } from "../common/utils/date-time-conversions";

const { width, height } = Dimensions.get("window");

const selectAReport = [
    { 
        label: 'Last month',
        value: '1'
    },
    {
        label: 'Custom dates',
        value: '2'
    },
]

const DateFilterModal = (props: {
    selectedOption: any,
    setSelectedOption: (option: any) => void,
    filterDate: (setDates: any) => void,
    handleCancel: () => void,
    //handleCustomDatePicker: (date: string, pickerType: 'start' | 'end') => void,
    customDates: { start: string | null, end: string | null },
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [customDate, setCustomDate] = useState<number>(0); //0 = nothing , 1= 1month, 2= custom date
    const [storedDates, setStoredDates] = useState<{start: string; end: string;}>({start: getOneMonthBackDate(), end: getCurrentDate()})
    const [editable, setEditable] = useState(true);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option:any) => {
        props.setSelectedOption(option.label);
        if(option.label === 'Custom dates'){
            setCustomDate(option.value);
            //set defaults
            setStoredDates({
                start: getOneMonthBackDate(),
                end: getCurrentDate(),
            })
            setEditable(true); // DatePicker should not be editable
        } else if(option.label === 'Last month'){
            setCustomDate(option.value);
            setStoredDates({
                start: getOneMonthBackDate(),
                end: getCurrentDate(),
            })
            setEditable(false); // DatePicker should not be editable
            
            //setIsOpen(false); // Close dropdown for "Last Month"
        }
        setIsOpen(false);
    };


    return(
        <TouchableWithoutFeedback
            onPress={() => {
                Keyboard.dismiss(); // Dismiss keyboard if open
                props.handleCancel(); // Dismiss the modal
            }}
        >
        <View style={styles.centeredView}>
            <View style={[styles.modalView, {zIndex: 1}]}>
                <Text className="text-base font-bold text-center text-white">
                    Filter settings
                </Text>
                <Text className="px-3 py-2 text-sm font-medium text-center text-white">
                    Select a date range to view your charger performance:
                </Text>
                <View className="relative" style={{position: 'relative', zIndex: 2}}>
                    <Pressable 
                        onPress={toggleDropdown} 
                        style={[styles.dropdownTrigger, styles.BgColor3]} 
                        className="flex-row justify-between px-3 py-2 rounded-full"
                    >
                        <Text className="text-sm font-medium text-white">
                            {props.selectedOption || 'Select dates'}
                        </Text>
                        <FontAwesome6 
                            name={isOpen ? 'chevron-up' : 'chevron-down'} 
                            size={16} 
                            color="white" 
                        />
                    </Pressable>
                    {isOpen && (
                        <View 
                            style={[
                                styles.dropdownContainer,
                                {zIndex: 3, elevation: 6}
                            ]}
                        >
                        {selectAReport.map(option => (
                            <Pressable 
                                key={`reportSelect${option.value}`} 
                                onPress={() => selectOption(option)} 
                                style={{ padding: 10 }}
                            >
                                <Text>{option.label}</Text>
                            </Pressable>
                        ))}
                        </View>
                    )}
                    {customDate !== 0 && (
                        <View style={{ height: 80, justifyContent: 'center' }}>
                            <Text className="mb-1 text-sm font-medium text-white">Select a start and end date:</Text>
                            <View className="flex-row items-center justify-between">
                                {customDate === 1 ? (
                                    <>
                                        <Text className="px-5 py-2 text-white rounded-full" style={styles.BgColor4}>{getOneMonthBackDate()}</Text>
                                        <Text className="text-sm font-medium text-white">to</Text>
                                        <Text className="px-5 py-2 text-white rounded-full" style={styles.BgColor4}>{getCurrentDate()}</Text>
                                    </>
                                ) : (
                                    <>
                                        <DatePicker 
                                            customDatePicker={(date: string) => setStoredDates({...storedDates, start: date})}
                                            prev={1}
                                            initialDate={props.customDates.start} // use the passed date
                                            editable={editable}
                                        />
                                        <Text className="text-sm font-medium text-white">to</Text>
                                        <DatePicker 
                                            customDatePicker={(date: string) => setStoredDates({...storedDates, end: date})}
                                            prev={0}
                                            initialDate={props.customDates.end} // use the passed date
                                            editable={editable}
                                        />
                                    </>
                                )}
                            </View>
                        </View>
                    )}   
                </View>
                <View 
                    className="flex-row items-center justify-between px-2 py-1"
                    style={{margin: 15}}
                >
                    <Pressable 
                        onPress={props.handleCancel}
                        className="border border-white rounded-full"
                    >
                        <Text className="px-4 py-2 text-xs font-semibold text-white rounded-full">
                            Cancel
                        </Text>
                    </Pressable>
                    <Pressable onPress={() => 
                        {
                            setCustomDate(0);
                            props.filterDate(storedDates)
                            
                    }}>
                        <Text 
                            className="px-4 py-2 text-xs font-semibold text-white rounded-full"
                            style={styles.BgColor}>
                                Save Settings
                        </Text>
                    </Pressable>
                </View>
            </View>
        </View>
        </TouchableWithoutFeedback>
    )
}

export default DateFilterModal;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
      },
    containerBgColor: {
        backgroundColor: '#0B1E1D',
    },
    BgColor: {
        backgroundColor: '#51B868'
    },
    BgColor1: {
        backgroundColor: '#39BEB780'
    },
    BgColor2: {
        backgroundColor: '#F97777'
    },
    BgColor3: {
        backgroundColor: '#39BEB7'
    },
    BgColor4:{
        backgroundColor: '#636363'
    },
    TextColor1: {
        color: '#E8E8E9'
    },
    textColor3: {
        color: "#8D8E90"
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    filterIcon: {
        height: 17,
        width: 17,
        backgroundColor: '#39BEB7',
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position:'relative',
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
      },
    modalView: {
        margin: 20,
        backgroundColor: "#292D33",
        borderRadius: 30,
        padding: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: 290,
        // height: 325,
    },
    dropdownTrigger: {
        width: width <= 430 ? width*0.4 : 150,
    },
    dropdownContainer: {
        position: 'absolute',
        top: 30,
        left: 0,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginTop: 2,
        width: width <= 430 ? width * 0.4 : 150,
        zIndex: 1000,
        elevation: 5,
    },
    alignEllipses: {
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 150,
    },
    tooltipContainer: {
        borderRadius: 10,
        padding: 10,
        minWidth: 50,
        maxWidth: 500,
        width: 'auto',
        height: 'auto'
      }
})