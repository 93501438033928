import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions } from 'react-native';
import { IconButton } from 'react-native-paper';
import LoadingButton from '../components/LoadingButton';

const { width, height } = Dimensions.get('window');

const SignInFieldsOnly = (props: {
    onSubmit: (email: string, password: string) => void,
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });
    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInputChange = (field: string, value: string) => {
        let newErrors = { ...errors };
        
        switch (field) {
            case 'email':
                setEmail(value);
                newErrors.email = !value ? 'Please enter your email address.' : validateEmail(value) ? '' : 'Please enter a valid email address.';
                break;
            case 'password':
                setPassword(value);
                newErrors.password = value ? '' : 'Please enter a password.';
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    return (
        <SafeAreaView>
            <View style={[styles.containerBgColor, { height: height - 65, paddingBottom: 10 }]}>
                <View>
                    <Text className='pt-3 pb-2 text-base font-semibold text-white'>Email</Text>
                    <TextInput
                        placeholder="Enter your email"
                        placeholderTextColor={'#8D8E90'}
                        style={[styles.BgColor, { borderRadius: 25 }]}
                        className="w-full p-4 text-white"
                        value={email}
                        onChangeText={(value: string) => handleInputChange('email', value)}
                    />
                    {errors.email ? <Text style={styles.errorText}>{errors.email}</Text> : null}

                    <Text className='pt-3 pb-2 text-base font-semibold text-white'>Password</Text>
                    <TextInput
                        placeholder="Enter your password"
                        placeholderTextColor={'#8D8E90'}
                        style={[styles.BgColor, { borderRadius: 25 }]}
                        className="w-full p-4 text-white"
                        secureTextEntry={!isPasswordVisible}
                        value={password}
                        onChangeText={(value: string) => handleInputChange('password', value)}
                    />
                    <IconButton
                        icon={(props) => (
                            <MaterialCommunityIcons
                            name={isPasswordVisible ? "eye" : "eye-off"}
                            {...props}
                            style={{ color: "#01556F" }}
                            />
                        )}
                        onPress={() => setPasswordVisible(!isPasswordVisible)}
                        style={styles.alignIcon}
                    />
                    {errors.password ? <Text style={styles.errorText}>{errors.password}</Text> : null}

                    {/* <Pressable onPress={() => props.onSubmit(email, password) } 
                        style={styles.button} 
                        className='p-4 mt-5 text-center rounded-full'
                    >
                        <Text className='text-lg font-medium text-center text-white'>
                            Sign in
                        </Text>
                    </Pressable> */}
                    <View style={{marginTop: 15}}>
                        <LoadingButton 
                            onPress={async () => props.onSubmit(email, password) }
                            className='p-4 mt-5 text-center rounded-full'
                        >
                            Sign in
                        </LoadingButton>
                    </View>

                </View>
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    p4: {
        padding: 16,
    },
    subHeaderText: {
        color: '#8D8E90',
    },
    BgColor: {
        backgroundColor: '#292D33',
    },
    errorText: {
        color: '#f38787',
        fontSize: 14,
        marginTop: 4,
    },
    button: {
        backgroundColor: '#39BEB7',
    },
    alignIcon: {
        position: 'absolute',
        top: '8.7rem',
        alignSelf: 'flex-end'
    }
});

export default SignInFieldsOnly;
