import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ActiveChargeStatus, ChargerManufacturerInfo } from "../models/charger-model";
import { ApiRequest, ApiResponse, fetchDataFromAPI, fetchDataFromAPITyped } from "./api-requests";

export const checkinCharger = async (orderId: string): Promise<ApiResponse<null | undefined>> => {
    //check in the charger
    const apiQuery: ApiRequest = {
        route: "charger/checkin",
        request: {
            method: 'POST', 
            entity: 'charger', 
            body: {
                orderId: orderId
            }
        }

    };
    return fetchDataFromAPITyped(apiQuery);
}

export const checkoutCharger = async (orderId: string, email: string) => {
    //Check out the charger using the checkout API
    const d = new Date().getTimezoneOffset();
    const apiQuery: ApiRequest = {
        route: 'charger/checkout',
        request: {
            method: 'POST',
            entity: 'charger',
            body: {
                orderId: orderId,
                tz_offset: d,
                email: email
            }
        }

    }
  
    return await fetchDataFromAPITyped<null>(apiQuery);
    // TODO: Check the response for success here
}

export const getActiveChargeData = async (orderId: string) => {
    const apiRequest: ApiRequest = {
        route: 'charger/getActiveChargeData',
        request: {
            method: 'POST',
            entity: 'charger',
            body: {orderId: orderId}
        }
    }
    return fetchDataFromAPITyped<ActiveChargeStatus>(apiRequest);
}

export const getUserActiveCharges = async () => {
    const apiRequest: ApiRequest = {
        route: 'user/getActiveCharges',
        request: {
            method: 'GET',
            entity: 'user',
            body: undefined
        }
    }
    return fetchDataFromAPITyped<any[]>(apiRequest);
}

export const getManufacturerInfoForCharger = async (serialNumber: string) => {
    const apiRequest: ApiRequest = {
        route: `charger/${serialNumber}/manufacturerInfo`,
        request: {
            method: 'GET',
            entity: 'charger',
            body: undefined
        }
    }
    return fetchDataFromAPITyped<ChargerManufacturerInfo>(apiRequest)
}

export const getSingleChargerData = async (chargerId: string) => {
    try {
        const apiQuery = PrepareApiProcedure(
            `charger/getSingleCharger`, 
            'POST', 
            'charger', 
            {
                chargerId: chargerId
            });
        const singleCharger = await fetchDataFromAPI(apiQuery);
        return singleCharger[0];
    } catch (error) {
        console.log(error);
    }
}