import React, {useEffect, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    TextInput,
    ScrollView,
  } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { generatePeakObj, setPeak, WeekConfiguration } from "../components/common/utils/peak-off-peak-field";
import GooglePlacesInput from "../components/GooglePlacesInput";
import { 
    getCoordinatesFromGeodata, 
    GetGoogleGeocode as GetGoogleGeodata, 
    getAddressComponentsFromGeodata 
} from '../components/common/utils/google-api-utils';
import { Place } from "react-native-google-places-autocomplete";
import { 
    disableCharger, 
    enableCharger, 
    getAllChargers, 
    getMyChargers, 
    getResidentials, 
    registerNewCharger, 
    updateCharger, 
    updateSelectedCharger
} from "../store/actions/charging-actions";
import { isSuccessful } from "../services/api-requests";
import {useDispatch} from 'react-redux';
import { notificationService } from "../services/notification-service";
import { ChargerAttributes, createEmptyCharger } from "../models/charger-model";
import RegisterChargerCheckbox from "./RegisterChargerCheckbox";
import { AppDispatch } from "../store";
import LoadingButton from "../components/LoadingButton";
import { getSingleChargerData } from "../services/charger-service";
import { profanity } from '@2toad/profanity';

const { width, height } = Dimensions.get("window");

interface AddModifyChargerProps {
    navigation: any;
    route: any;
  }
  
  interface ErrorTextProps {
    message: string;
  }

  // Reusable Error Text Component
const ErrorText: React.FC<ErrorTextProps> = ({ message }) => (
    message ? <Text style={{ color: 'red', marginVertical: 5 }}>{message}</Text> : null
);

const AddModifyCharger: React.FC<AddModifyChargerProps> = ({ navigation, route }) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [geodata, setGeodata] = React.useState(null);

    const [peakSelection, setPeakSelection] = React.useState(generatePeakObj('000000000000000000000000000000000000000000'));
    const [uuid, setUuid] = React.useState<string | undefined>();
    const [editChargerStatus, setEditChargerStatus] = useState(false);
    const [checkboxSelected, setCheckboxSelected] = useState({
        updatedDay: [''],
        startEndTime: [{}]
    });
    const[generatePeakObjCheckbox, setGeneratePeakObjCheckbox] = useState({});
    const [residentialRatePeak, setResidentialRatePeak] = useState<number | null>(null);
    const [residentialRateOffPeak, setResidentialRateOffPeak] = useState<number | null>(null);
    const [chargingCapacity, setChargingCapacity] = useState<number>(route.params.capacity ?? 40); //default 40A
    const [addressErrorMessage, setAddressErrorMessage] = useState<string>('');
    const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
    const [peakSelectionErrors, setPeakSelectionErrors] = useState<boolean>(false);
    const [descriptionErrorMessage, setDescriptionErrorMessage] = useState<string>('');
    const [remainingChars, setRemainingChars] = useState(50);
    const manufacturer = route.params.manufacturer
    const [form, setForm] = React.useState<ChargerAttributes>(
        createEmptyCharger()
    );
    const [rawPeak, setRawPeak] = React.useState<string>("");
    const [rawOffPeak, setRawOffPeak] = React.useState<string>("");
    const[isEnabled, setIsEnabled] = React.useState<boolean>(route.params?.chargerDetails?.is_enabled ?? true);

    const dispatch: AppDispatch = useDispatch();

    const setSelectedDays = (objCheckbox: any) => {
        setGeneratePeakObjCheckbox(objCheckbox);
    }

    const isPeakSelectionError = (isError: boolean) => {
        setPeakSelectionErrors(isError);
    }

    const registerPeakSelection = (peakSelection: WeekConfiguration) => {
        setPeakSelection(peakSelection);
    }

    const handleDescriptionChange = (text: string) => {
        if(profanity.exists(text)){
            setDescriptionErrorMessage('Please, no profanity in the description');
        } else {
            if(descriptionErrorMessage !== ''){
                setDescriptionErrorMessage('');
            }
        }
        setForm({
          ...form,
          description: text,
        });
    };


    const handleNameChange = (text: string) => {
        if (profanity.exists(text)) {
            setNameErrorMessage('Please, no profanity in the name');
        } else if (text.length > 50) {
            setNameErrorMessage('Name cannot exceed 50 characters');
        } else {
            if (nameErrorMessage !== '') {
                setNameErrorMessage('');
            }
        }

        setRemainingChars(50 - text.length);
    
        setForm({
            ...form,
            name: text,
        });
    };

    useEffect( () => {

        const getChargingDetails = async() => {
            //check if we have been passed in a route
            if(route.params){
                if(route.params.chargerDetails){
                //const editChargerStatus = route.params?.editCharger;
                setEditChargerStatus(true);
                const chargerDetails = route.params?.chargerDetails;
                const chargerData = await getSingleChargerData(chargerDetails.uuid);
                
                const{name, unit_serial, full_address, description, price_kwh_peak, price_kwh_off_peak, peak_selection, uuid, is_enabled, lat, lng} = chargerData;
                setIsEnabled(is_enabled);
                setForm({
                    ...form,
                    name: name,
                    unit_serial: unit_serial,
                    full_address: full_address,
                    description: description,
                    price_kwh_peak: price_kwh_peak,
                    price_kwh_off_peak: price_kwh_off_peak,
                    uuid: uuid
                })
                //set the residentials
                if(lat && lng){
                    const residentials = await getResidentials(lat.toString(), lng.toString());
                    setResidentialRateOffPeak(residentials?.outputs.residential)
                    setResidentialRatePeak(residentials?.outputs.residential * 2)
                }

                setRawPeak(price_kwh_peak);
                setRawOffPeak(price_kwh_off_peak)
                setPeakSelection(peak_selection);
                setRemainingChars(50 - name.length);

                const getGeneratedPeakObj = generatePeakObj(peak_selection)
                
                let dateUpdated = [];
                let updatedDateTime = [];

                for (const key in getGeneratedPeakObj) {
                    if (getGeneratedPeakObj?.hasOwnProperty(key)) {
                    if(getGeneratedPeakObj[key].start !== 0 || getGeneratedPeakObj[key].end !== 0){
                        dateUpdated.push(key);
                        updatedDateTime.push(getGeneratedPeakObj[key])
                    }
                    }
                }

                setCheckboxSelected({
                    ...checkboxSelected,
                    updatedDay: dateUpdated,
                    startEndTime: updatedDateTime
                })
                setGeneratePeakObjCheckbox(getGeneratedPeakObj)
                }
            else{
                    setForm({
                        ...form,
                        unit_serial: route.params.unit_serial,
                        name: route.params.name ?? '',
                    })
                }
            }
        }

        getChargingDetails();
    }, [])

    useEffect(()=> {
        const fullAddress = form?.full_address;
        if(fullAddress){
            const googleAddress = {
                description : fullAddress
            }
            handleAddressSelect(googleAddress, '')
        }
        
    },[form?.full_address])

    const handleUpdateCharger = async () => {
        setIsUpdating(true)
        const { description, lat, lng, uuid, name, unit_serial, full_address, street_number, street_name, zip_code, city, state, country, 
            price_kwh_peak, price_kwh_off_peak
        } = form;

        const respPeakSelection = setPeak(peakSelection)

        let cost_est_kwh_peak_update;
        let cost_est_kwh_off_peak_update;

        if( residentialRatePeak !== null && residentialRateOffPeak !== null){
            cost_est_kwh_peak_update  = residentialRatePeak  * chargingCapacity * 2;
            cost_est_kwh_off_peak_update = residentialRateOffPeak * chargingCapacity;
        } else {
            cost_est_kwh_peak_update  = 3 * chargingCapacity;
            cost_est_kwh_off_peak_update = 3 * chargingCapacity;
        }

        const payload = {
            charger_model: "Emporia X1",
            charger_type: "Level 2",
            charging_capacity: 40,
            cost_est_kwh: 2.88,
            description: description,
            manufacturer_name: manufacturer,
            name,
            unit_serial: unit_serial,
            lat,
            lng,
            full_address,
            street_number,
            street_name,
            zip_code,
            city,
            state,
            country,
            price_kwh_peak,
            price_kwh_off_peak,
            cost_est_kwh_peak: cost_est_kwh_peak_update,
            cost_est_kwh_off_peak: cost_est_kwh_off_peak_update,
            peak_selection: respPeakSelection
        }

        navigation.navigate('MyChargers',{
            state: route?.params?.state === false ? true : false
        });

        updateCharger(payload, uuid!);
    }

    const onEnableDisableCharger = async () => {
        const enableDisableuuid = route.params?.chargerDetails?.uuid
        if(isEnabled === true){
            //dispatch(disableCharger(enableDisableuuid))
            await disableCharger(enableDisableuuid);
            dispatch(updateSelectedCharger(enableDisableuuid));
            setIsEnabled(!isEnabled);
        }
        else{
            //dispatch(enableCharger(enableDisableuuid))
            await enableCharger(enableDisableuuid);
            dispatch(updateSelectedCharger(enableDisableuuid));
            setIsEnabled(!isEnabled);
        }
    }

    const handleSubmit = async () => {
        // Validate required fields
        if (!form.full_address) {
          setAddressErrorMessage('Please select a valid address');
          return;
        }
        if (!form.name) {
          setNameErrorMessage('Please enter a charger name');
          return;
        }
      
        // Async call to set peak selection
        const respPeakSelection = setPeak(peakSelection);
      
        // Helper function for cost calculation with error handling
        const calculateCost = (rate: number | null, multiplier: number) => {
          if (rate === null || rate < 0 || isNaN(rate)) {
            console.error(`Invalid rate provided: ${rate}`);
            return 3 * multiplier; // Default value in case of invalid input
          }
          return rate * multiplier;
        };
      
        // Perform cost calculations
        const cost_est_kwh_peak_update = calculateCost(residentialRatePeak, chargingCapacity * 2);
        const cost_est_kwh_off_peak_update = calculateCost(residentialRateOffPeak, chargingCapacity);
      
        // Clone form and remove the uuid property
        const { uuid, ...formWithoutUuid } = form;

        // Prepare payload by spreading form directly and adding additional fields
        const payload = {
          ...formWithoutUuid,
          charger_model: "Emporia X1",
          charger_type: "Level 2",
          charging_capacity: 40,
          cost_est_kwh_peak: cost_est_kwh_peak_update,
          cost_est_kwh_off_peak: cost_est_kwh_off_peak_update,
          peak_selection: respPeakSelection,
          manufacturer_name: manufacturer, // Additional field
        };
      
        const registerResp = await registerNewCharger(payload)
        // Handle registration response
        if (!isSuccessful(registerResp.status)) {
            notificationService.sendNotification("error", "Could not register this charger");
        } else {
            //notificationService.sendNotification("success", "Successfully registered your charger!");
            await enableCharger(registerResp?.payload?.dataValues?.uuid); //TODO: verify enabled
            setUuid(registerResp?.payload?.uuid);
            dispatch(getMyChargers());
            navigation.navigate('MyChargers');
        }
    };

    const handleAddressSelect = async (data: Place, details: any) => {
        const newGeodata = await GetGoogleGeodata(data.description);
        const { latitude, longitude } = getCoordinatesFromGeodata(newGeodata);
        const addressComponents = getAddressComponentsFromGeodata(newGeodata);
    
        if (!latitude || !longitude) {
          setAddressErrorMessage('Please select a valid address');
        } else {
          setAddressErrorMessage('');
          setForm({
            ...form,
            full_address: data.description,
            lat: latitude,
            lng: longitude,
            ...addressComponents,
          });
          setGeodata(newGeodata);
          //get residential rates
          const residentials = await getResidentials(latitude.toString(), longitude.toString());
          setResidentialRateOffPeak(residentials?.outputs.residential)
          setResidentialRatePeak(residentials?.outputs.residential * 2);
        }
    };

    
    const handleKwhChange = (key:string, text: string) => {
        const regex = /^(0(\.\d*)?|\.?\d*)$/; // Matches valid decimal inputs below 1
    
        if (regex.test(text)) {
            if(key === 'price_kwh_peak'){
                setRawPeak(text); // Update raw input state
            } else if(key === 'price_kwh_off_peak'){
                setRawOffPeak(text);
            }
            
            // Parse text to a number if it's a valid numeric value
            const numericValue = parseFloat(text);
            setForm({
                ...form,
                [key]: isNaN(numericValue) ? 0 : parseFloat(numericValue.toFixed(2)), // Ensure it’s valid
            });
        }
    };

    React.useEffect(() => {
        setTimeout(()=>{
            dispatch(getAllChargers());
        },2000)
    }, [isEnabled]);
    
    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, {height: height - 65, paddingBottom: 10, flex: 1}]}>
                <ScrollView>
                    <View className="relative">
                        <Image
                        source={require("../../assets/RegisterBgImage.png")}
                        style={{ aspectRatio: 1}}
                        alt="Background Image"
                        />
                        {/* <Pressable className="absolute bottom-0 right-0 p-2 mb-2 mr-2 rounded-lg" style={{backgroundColor: '#0B1E1D'}}>
                            <Text className="text-sm font-semibold text-white">Change Photo</Text>
                        </Pressable> */}
                    </View>
                    
                    <View className="p-6">
                        {
                            editChargerStatus && (
                                <View>
                                    {isEnabled === false ? (
                                        <LoadingButton
                                            onPress={onEnableDisableCharger}
                                            className="p-3 mt-4 rounded-full"
                                            backgroundColor="#FBD644"
                                        >
                                            <Text className="text-lg font-bold text-center text-black">Resume MeanderEV Services</Text>
                                        </LoadingButton>
                                    ):(
                                        <LoadingButton
                                            onPress={onEnableDisableCharger}
                                            className="p-3 mt-4 rounded-full"
                                            backgroundColor="#008000bf"
                                        >
                                            <Text className="text-lg font-bold text-center text-black">Pause MeanderEV Services</Text>
                                        </LoadingButton>
                                    )}

                                    <Text className="mt-2 text-xs font-medium text-white">Temporarily disable or enable your charger -  Guests will not be able to find it or charge with it through the app. This is useful if you want to manually use the charger yourself without the app.</Text>
                                    <View style={styles.line} />
                                </View>
                            )
                        }
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Charger Name</Text>
                        <TextInput 
                            placeholder="Enter charger name"
                            placeholderTextColor={'#8D8E90'}
                            style={styles.BgColor1}
                            className="w-full p-3 text-white rounded-full"
                            value={form?.name}
                            autoComplete="off"
                            autoCorrect={false}
                            onChange={(e) => handleNameChange(e.nativeEvent.text)}
                        />
                        <View style={styles.meta}>
                            <ErrorText message={nameErrorMessage} />
                            <Text
                                style={[
                                    styles.charCount,
                                    { color: remainingChars < 0 ? 'red' : 'gray' },
                                ]}
                            >
                                {remainingChars}/50
                            </Text>
                        </View>
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Serial Number</Text>
                        <TextInput 
                            placeholder="Enter serial number"
                            placeholderTextColor={'#8D8E90'}
                            style={styles.BgColor1}
                            className="w-full p-3 text-white rounded-full"
                            value={form?.unit_serial}
                            autoComplete="off"
                            autoCorrect={false}
                            aria-disabled={true}
                        />
                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Street Address</Text>
                        <GooglePlacesInput onPlaceSelect={handleAddressSelect} value={form?.full_address}/>
                        <ErrorText message={addressErrorMessage} />

                        <Text className="pt-3 pb-2 text-base font-semibold text-white">Charger Description</Text>
                        <TextInput 
                            placeholder="Enter charger description"
                            placeholderTextColor={'#8D8E90'}
                            multiline={true}
                            numberOfLines={5}
                            style={[styles.BgColor1, { borderRadius: 16 }]}
                            className="w-full p-3 text-white"
                            value={form?.description}
                            onChange={(e) => handleDescriptionChange(e.nativeEvent.text)}
                        />
                        <ErrorText message={descriptionErrorMessage} />

                        <View style={styles.line} />

                        <View>
                            <Text className="text-base font-semibold text-white">Electricity Rate</Text>
                            <View className="flex flex-row items-center gap-4">
                                <Text className="w-24 text-base font-semibold text-white">Peak:</Text>
                                <View className="flex flex-row items-center gap-1">
                                    <FontAwesome name="dollar" size={18} color="white" />
                                    <TextInput
                                        placeholder="0.00"
                                        placeholderTextColor="#8D8E90"
                                        style={[styles.BgColor2, { borderRadius: 30 }]}
                                        className="w-24 p-3 text-white"
                                        value={rawPeak} // Convert number to string for TextInput
                                        autoComplete="off"
                                        autoCorrect={false}
                                        keyboardType="decimal-pad" // Numeric keyboard
                                        onChangeText={(text) => handleKwhChange("price_kwh_peak", text)} // Updated to handle input change
                                    />
                                </View>
                                <Text className="text-xs font-medium text-white">per kWh before fees</Text>
                                { residentialRatePeak &&
                                    <Text style={{ color: 'red' }} className="text-xs">
                                        Est: ${(residentialRatePeak).toFixed(2)}/kWh
                                    </Text>
                                }
                            </View>
                            <View className="flex flex-row items-center gap-4">
                                <Text className="w-24 text-base font-semibold text-white">Off-Peak:</Text>
                                <View className="flex flex-row items-center gap-1">
                                    <FontAwesome name="dollar" size={18} color="white" />
                                    <TextInput
                                        placeholder="0.00"
                                        placeholderTextColor="#8D8E90"
                                        style={[styles.BgColor2, { borderRadius: 30 }]}
                                        className="w-24 p-3 text-white"
                                        value={rawOffPeak} // Convert number to string for TextInput
                                        autoComplete="off"
                                        autoCorrect={false}
                                        keyboardType="decimal-pad" // Numeric keyboard
                                        onChangeText={(text) => handleKwhChange("price_kwh_off_peak", text)} // Updated to handle input change
                                    />
                                </View>
                                <Text className="text-xs font-medium text-white">per kWh before fees</Text>
                                { residentialRateOffPeak &&
                                    <Text style={{ color: 'red' }} className="text-xs">
                                        Est: ${(residentialRateOffPeak).toFixed(2)}/kWh
                                    </Text>
                                }
                            </View>
                        </View>

                        <View style={styles.line} />

                        <View>
                            <Text className="mb-4 text-base font-semibold text-white">Peak Hours</Text>
                            <RegisterChargerCheckbox 
                                setPeakSelection={registerPeakSelection} 
                                checkboxSelected={checkboxSelected}
                                generatePeakObjCheckbox={generatePeakObjCheckbox} 
                                editChargerStatus={editChargerStatus}
                                selectionErrors={isPeakSelectionError}
                            />
                        </View>
                        {editChargerStatus ===  true ? (
                            <View style={{margin: 15}}>
                                <LoadingButton
                                    onPress={handleUpdateCharger}
                                    backgroundColor="#39BEB7"
                                    className="p-3 mt-4 rounded-full"
                                    disabled={
                                        isUpdating ||
                                        descriptionErrorMessage !== '' ||
                                        nameErrorMessage !== '' ||
                                        addressErrorMessage!== '' ||
                                        peakSelectionErrors
                                    }
                                >
                                    Update Charger
                                </LoadingButton>
                            </View>
                        ) : (
                            // <Pressable onPress={handleSubmit} style={{backgroundColor: '#39BEB7'}} className="p-3 mt-4 rounded-full">
                            // <Text className="text-lg font-medium text-center text-white">Create Charger</Text>
                            // </Pressable>
                            <View style={{margin: 15}}>
                                <LoadingButton
                                    onPress={handleSubmit}
                                    backgroundColor="#39BEB7"
                                    className="p-3 mt-4 rounded-full"
                                    disabled={
                                        isUpdating ||
                                        descriptionErrorMessage !== '' ||
                                        nameErrorMessage !== '' ||
                                        addressErrorMessage !== '' ||
                                        peakSelectionErrors
                                    }
                                >
                                    Create Charger
                                </LoadingButton>
                            </View>
                        )}
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    )
}

export default AddModifyCharger;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
    },
    containerBgColor: {
        backgroundColor: '#0B1E1D',
    },
    BgColor1: {
        backgroundColor: '#292D33'
    },
    BgColor2: {
        backgroundColor: '#234D4E'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        borderRadius: 5,
    },
    meta: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    charCount: {
        fontSize: 12,
    },
})