import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { OrderAttributes } from "../models/order-model";
import { StatusResponse } from "../models/status-response-model";
import { ApiRequest, ApiResponse, fetchDataFromAPI, fetchDataFromAPITyped } from "./api-requests";

export const createOrder = async (chargerId: string): Promise<ApiResponse<OrderAttributes | null>> => {

    const apiPaymentMethodsQuery: ApiRequest = {
      route: `orders`,
      request: {
        method: 'POST',
        entity: 'order',
        body: { chargerId: chargerId }
      }
    }
    return await fetchDataFromAPITyped(apiPaymentMethodsQuery)
}

export const updateOrderEmail = async (orderId: string, email: string): Promise<OrderAttributes | null> => {
  try {
    const apiQuery = PrepareApiProcedure(
      `orders/updateEmail`,
      `PUT`,
      `order`,
      {
        orderId: orderId,
        email: email
      }
    );
    return await fetchDataFromAPI(apiQuery);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const cancelOrder = async (orderId: string): Promise<ApiResponse<OrderAttributes | null>> => {
  const apiQuery: ApiRequest = {
    route: `orders/${orderId}`,
    request: {
      method: 'DELETE',
      entity: 'order',
      body: undefined
    }
  }  
  
  return fetchDataFromAPITyped<OrderAttributes>(apiQuery);
}

export const reassignOrder = async (orderId: string): Promise<ApiResponse<any>> => {
  const apiQuery: ApiRequest = {
    route: `orders/reassign`,
    request: {
      method: 'PUT',
      entity: 'order',
      body: {orderId: orderId}
    }
  }

  return fetchDataFromAPITyped<any>(apiQuery);

}