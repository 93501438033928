import { ApiRequest, fetchDataFromAPITyped } from "../services/api-requests";
import { PaymentDetails } from '../models/payment-model';

export const getPaymentDetails = async (orderId: string) => {
  const apiQuery: ApiRequest = {
    route: "payments/details",
    request: {
      method: 'GET',
      entity: "payments",
      body: null,
      searchParams: new URLSearchParams({orderId: orderId})
    }
  }
  return await fetchDataFromAPITyped<PaymentDetails>(apiQuery);
}