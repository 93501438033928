import React, { useState } from 'react';
import { Animated, Pressable, Text, ActivityIndicator, StyleSheet, View } from 'react-native';

interface LoadingButtonProps {
    onPress: () => Promise<void>;
    disabled?: boolean;
    className?: string;
    children: React.ReactNode;
    backgroundColor?: string; // Optional background color
  }
  
  const LoadingButton: React.FC<LoadingButtonProps> = ({
    onPress,
    disabled,
    className,
    children,
    backgroundColor = '#3FC67D', // Default color
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const animatedValue = useState(new Animated.Value(1))[0];
  
    const handlePress = async () => {
      if (isLoading) return; // Prevent duplicate presses
      setIsLoading(true);
      try {
        await onPress(); // Await the promise returned by onPress
      } catch (error) {
        console.error('Error during button action:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    const fadeToDisabledColor = () => {
      Animated.timing(animatedValue, {
        toValue: 0, // Fade to disabled
        duration: 300, // Transition duration
        useNativeDriver: false,
      }).start();
    };
  
    const fadeToEnabledColor = () => {
      Animated.timing(animatedValue, {
        toValue: 1, // Fade to enabled
        duration: 300, // Transition duration
        useNativeDriver: false,
      }).start();
    };
  
    if (disabled || isLoading) {
      fadeToDisabledColor();
    } else {
      fadeToEnabledColor();
    }
  
    const interpolatedBackgroundColor = animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['#bdc3c7', backgroundColor], // From disabled to enabled color
    });
  
    return (
      <Animated.View style={{ backgroundColor: interpolatedBackgroundColor, borderRadius: 24 }}>
        <Pressable
          onPress={handlePress}
          disabled={disabled || isLoading}
          className={className}
          style={styles.pressable}
        >
          <View style={styles.content}>
            {isLoading && <ActivityIndicator style={styles.spinner} color="#fff" />}
            <Text style={styles.text}>{children}</Text>
          </View>
        </Pressable>
      </Animated.View>
    );
  };
  
  const styles = StyleSheet.create({
    pressable: {
      borderRadius: 24,
      paddingVertical: 10,
      paddingHorizontal: 20,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      margin: 5, // Default margin around the button
    },
    text: {
      color: '#fff',
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    content: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    spinner: {
      marginRight: 8, // Space between spinner and text
    },
  });

export default LoadingButton;