import React, { useState } from "react";
import {
  View,
  Text,
  Button,
  Alert,
  TouchableWithoutFeedback,
  Keyboard,
  Pressable,
  GestureResponderEvent,
  KeyboardAvoidingView,
  Image,
  Dimensions,
  StyleSheet,
  TextInput,
  Platform,
  ScrollView,
} from "react-native";
import Constants from "expo-constants";
import * as Device from "expo-device";
// import { styles } from '../styles/LoginStyles';
import { LinearGradient } from "expo-linear-gradient";
import { loginUser, validateUserToken } from "../store/actions/user-actions";
import { createUserSession } from "../models/user-session-model";
import { notificationService } from "../services/notification-service";

import Logo from "../../assets/logo.png";
import Mountain from "../../assets/mountain.png";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { IconButton } from "react-native-paper";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { setSession } from "../store/actions/user-session";

//https://www.npmjs.com/package/rn-material-ui-textfield
//https://stackoverflow.com/questions/61398426/is-it-possible-to-use-material-ui-library-with-react-native

const LoginScreen = ({ navigation }) => {
  const fieldRef = React.createRef();
  // const [username, setUsername] = useState('');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });
  const [getIpAddress, setIpAddress] = useState<string>("");
  const [isPasswordVisible, setPasswordVisible] =
    React.useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch();

  console.log("navigation", navigation);

  const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

  const handlePress = () => {
    // Hide the keyboard
    Keyboard.dismiss();
  };

  const validateEmail = (email: any) => {
    // Basic email validation using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: GestureResponderEvent) => {
    event.preventDefault();
    const newErrors = { ...errors };
    if (!email) {
      newErrors.email = "Please enter your email address.";
    } else if (!validateEmail(email)) {
      newErrors.email = "Please enter a valid email address.";
    } else {
      newErrors.email = "";
    }

    if (!password) {
      newErrors.password = "Please enter a password.";
    } else {
      newErrors.password = "";
    }

    setErrors(newErrors);

    // If any error exists, return early
    if (Object.values(newErrors).some((error) => error !== "")) {
      return;
    }

    const loginObj = createUserSession({
      email: email,
      password: password,
      IpAddress: getIpAddress,
    });

    const resp = await loginUser(loginObj);
    //console.log("logged in with:", resp)

    if (resp) {
      const sessionData = await validateUserToken();
      //console.log("retrieved login token!");
      const userInfo = {
        name: email,
        email: email,
        password: password,
      };
      dispatch(setSession(sessionData));
      navigation.navigate("ChargerSelection", {
        userInfo: userInfo,
        auth: "NormalLogin",
      });
    } else {
      console.log("error retrieving the login token");
    }
  };

  React.useEffect(() => {
    const getUserIp = async () => {
      const getIpAddress = await getIpAddressFunc();
      console.log("IP address retrieved: ", getIpAddress);
      setIpAddress(getIpAddress);
    };

    getUserIp();
  }, []);

  const handleSignUp = () => {
    navigation.navigate("SignUp");
  };
  // Use a ref to access the input elements directly
  const emailRef = React.useRef<TextInput>(null);
  const passwordRef = React.useRef<TextInput>(null);

  // Track the actual values of the input fields in useEffect
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (emailRef.current && emailRef.current?.props.value !== email) {
        setEmail(emailRef.current.props.value ?? "");
      }
      if (passwordRef.current && passwordRef.current?.props.value !== password) {
        setPassword(passwordRef.current.props.value ?? "");
      }
    }, 500); // Check every 500ms (adjust if necessary)

    return () => clearInterval(interval);
  }, [email, password]);

  return (
    <ScrollView>
      <LinearGradient
        colors={["#0F4736", "#00A59E"]}
        style={[
          styles.container,
          { width: windowWidth <= 430 ? windowWidth : 430 },
        ]}
        start={{ x: 1, y: 0 }}
        end={{ x: 0, y: 0 }}
      >
        <View>
          <View>
            <View className="items-center mt-4">
              <Image
                source={Logo}
                style={{ width: 135, height: 50 }}
                alt="logo"
              />
            </View>
            <View className="mt-2">
              <Image
                source={Mountain}
                style={{
                  width: windowWidth <= 430 ? windowWidth : 430,
                  height: 145,
                }}
                alt="logo"
              />
            </View>
          </View>
          <View
            className="bg-white"
            style={{ alignItems: "center", height: windowHeight * 0.8 }}
          >
            <Text style={{ color: "#003849" }} className="text-xl font-bold">
              Sign In
            </Text>
            <View style={{ width: windowWidth < 300 ? windowWidth - 10 : 300 }}>
              <Pressable activeOpacity={1}>
                <TextInput
                  ref={emailRef}
                  style={styles.InputBox}
                  onChange={(e) => {
                    setEmail(e.nativeEvent.text);
                    setErrors({ ...errors, email: "" });
                  }}
                  value={email}
                  placeholder="Email"
                  placeholderTextColor="#000"
                />
              </Pressable>
              {!!errors.email && (
                <Text className="pl-2 text-red-600">{errors.email}</Text>
              )}

              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Pressable activeOpacity={1}>
                  <TextInput
                    ref={passwordRef}
                    style={styles.InputBoxPassword}
                    onChange={(e) => {
                      setPassword(e.nativeEvent.text);
                      setErrors({ ...errors, password: "" });
                    }}
                    value={password}
                    keyboardType="visible-password"
                    placeholder="Password"
                    placeholderTextColor="#000"
                    secureTextEntry={!isPasswordVisible}
                  />
                </Pressable>
                <IconButton
                  icon={(props) => (
                    <MaterialCommunityIcons
                      name={isPasswordVisible ? "eye" : "eye-off"}
                      {...props}
                      style={{ color: "#01556F" }}
                    />
                  )}
                  onPress={() => setPasswordVisible(!isPasswordVisible)}
                  style={{ width: `calc(100% - 70px)` }}
                />
              </View>
              {!!errors.password && (
                <Text className="pl-2 text-red-600">{errors.password}</Text>
              )}
              <View style={styles.btnContainer}>
                <LinearGradient
                  colors={["#0F4736", "#00A59E"]}
                  style={styles.gradient}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 1, y: 0 }}
                >
                  <Pressable
                    className="items-center pt-2 pb-2 pl-8 pr-8"
                    onPress={handleSubmit}
                  >
                    <Text className="text-sm text-white text-bold">
                      Sign In
                    </Text>
                  </Pressable>
                </LinearGradient>

                <View className="flex flex-row items-center pt-8 pb-4">
                  <Text style={styles.signInColor} className="text-base">
                    don’t have account?
                  </Text>
                  <Text
                    style={[styles.signInColor, styles.signInText]}
                    className="pl-1 text-bold"
                    onPress={handleSignUp}
                  >
                    Register
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </LinearGradient>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0,
  },
  InputBox: {
    height: 50,
    backgroundColor: "white",
    color: "#000",
    borderColor: "#003849",
    borderBottomWidth: 1,
    marginBottom: 1,
    width: "100%",
    fontSize: 14,
    paddingLeft: 8,
    outlineWidth: 0,
  },
  InputBoxPassword: {
    height: 50,
    backgroundColor: "white",
    color: "#000",
    borderColor: "#003849",
    borderBottomWidth: 1,
    marginBottom: 1,
    width: "180%",
    fontSize: 14,
    paddingLeft: 8,
    outlineWidth: 0,
  },
  inputContainer: {
    marginBottom: 10,
  },
  btnContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  gradient: {
    borderRadius: 80,
    marginTop: 25,
  },
  signInColor: {
    color: "#013C4C",
  },
  signInText: {
    fontWeight: "bold",
    textDecorationLine: "underline",
  },
});

// Get IpAddress
const getIpAddressFunc = async () => {
  const appVersion = process.env.REACT_NATIVE_APP_VERSION;
  const reactNativeVersion = Platform.Version || "Unknown";
  const userAgent = `MeanderEvApp/${appVersion} (ReactNative/${reactNativeVersion})`;
  const host = "meanderev.com"; // Replace this with your actual API host

  try {
    const response = await fetch(`https://ipapi.co/json`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        "User-Agent": userAgent,
        //"Host": host,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.statusNum === "3003") {
        /* do something */
      } else {
        notificationService.sendNotification(
          "error",
          `Cannot retrieve IP Address`
        );
      }
      return null;
    }

    const data = await response.json();
    console.log("getting IP address", data);

    const IpSend = data.ip;
    return IpSend;
  } catch (e) {
    console.log(e);
  }
};

export default LoginScreen;
