
// Function to load Google Maps API
const loadGoogleMapsAPI = (callback: () => void): void => {
  if (window.google && window.google.maps) {
    // Google Maps API is already loaded, call the callback function
    callback();
  } else {
    //const apiKey = process.env.REACT_NATIVE_GOOGLE_MAPS_API_KEY;
    // Google Maps API is not loaded, dynamically load it
    const script = document.createElement('script');
    //script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    //load the map in via proxy
    script.src = `${process.env.REACT_NATIVE_API_BASE_URL}googlemaps`;
    script.async = true;
    script.defer = true;
    script.onload = callback;

    // Append the script to the document
    document.head.appendChild(script);
  }
}
  
export default loadGoogleMapsAPI;
  