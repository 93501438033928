import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    Image,
    Dimensions,
    StyleSheet,
    Modal,
    ScrollView,
    Pressable,
    Platform,
    KeyboardAvoidingView,
    Animated,
    TouchableWithoutFeedback,
  } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import DatePicker from "../components/DateTimePicker";
import { getCurrentDate, getOneMonthBackDate } from "../components/common/utils/date-time-conversions";
import { ChargerAttributes } from "../models/charger-model";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store";
//import { getAllChargers, getMyChargers } from "../store/actions/charging-actions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { fetchDataFromAPI } from "../services/api-requests";
import { getMyChargers } from "../store/actions/charging-actions";
import { notificationService } from "../services/notification-service";
import { Tooltip } from 'react-native-elements';
import { color } from "react-native-elements/dist/helpers";
import { blue } from "react-native-reanimated/lib/typescript/reanimated2/Colors";
import MyChargerInfoCard from "./MyChargerInfoCard";
import DateFilterModal from "../components/modals/DateFilterModal";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { getMyChargerHistory } from "../services/charger-history-service";
import { ChargerHistoryAttributes } from "../models/charger-history-model";
import MyChargerHistoryAtAGlance from "./MyChargingHistoryAtAGlance";
import ChargeHistoryCard from "./ChargeHistoryCard";
import { AppDispatch } from "../store";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const { width, height } = Dimensions.get("window");

export default function MyChargers({navigation, route}) {

    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [customDates, setCustomDates] = useState<{ start: string | null, end: string | null }>({
        start: getOneMonthBackDate(),
        end: getCurrentDate(),
    });
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalKwh, setTotalKwh] = useState(0);
    const myChargersStore: ChargerAttributes[] = useSelector((state: RootState) => state.chargers.myChargers);

    const dispatch: AppDispatch = useDispatch();

    const [filteredChargers, setFilteredChargers] = React.useState<ChargerHistoryAttributes[]>([]);
    const [myChargerHistory, setMyChargerHistory] = React.useState<ChargerHistoryAttributes[]>([]);
    //const[chargerAssociation, setChargerAssociation] = useState<ChargerAttributes[] | undefined>([])

    const [view, setView] = useState<'list' | 'history'>('list');

    const [chargeHistoryFilter, setChargeHistoryFilter] = React.useState<string>(''); //when a user filters by charger

    const [currentPageSh, setCurrentPageSh] = useState(1); //Show History current page
    const [currentPageCl, setCurrentPageCl] = useState(1); //Charger List current page
    const scrollY = useRef(new Animated.Value(0)).current;

    const itemsPerPage = 10;
    
    const handlePageChange = (page: number) => {
        if (view === 'list') {
            setCurrentPageCl(page); // Update current page for Charger List
        } else if (view === 'history') {
            setCurrentPageSh(page); // Update current page for Show History
        }
    };
        
    // Animation for shrinking the top section
    const shrinkAnimation = scrollY.interpolate({
        inputRange: [0, 300],
        outputRange: [350, 200],
        extrapolate: 'clamp',
    });
    const textScaleAnimation = scrollY.interpolate({
        inputRange: [0, 300],
        outputRange: [1, 0.8], // Scale text dynamically
        extrapolate: 'clamp',
    });

    useEffect(() => {
        scrollY.addListener(({ value }) => {
          console.log('ScrollY Value:', value);
        });
      }, []);


    const handleSetSelectedOption = (option: any) => {
        setSelectedOption(option);
    }

    const historySumWithDateRange = (history: ChargerHistoryAttributes[]) => {
        let total_charge_price = 0;
        let total_kwh_calc = 0;

        history.forEach((h) => {
            const start = dayjs(h.charging_start_time);
            const end = dayjs(h.charging_end_time);
            const rangeStart = dayjs(customDates.start);
            const rangeEnd = dayjs(customDates.end).add(1, 'day').startOf('day');

            // Check if the charger's date is within the range
            if (start.isBetween(rangeStart, rangeEnd, null, '[]') && end.isBetween(rangeStart, rangeEnd, null, '[]')) {
                total_charge_price += h.total_charge_price ?? 0;
                total_kwh_calc += h.total_kwh ?? 0;
            }
        })

        return {
            total_charge_price: total_charge_price.toFixed(2),
            total_kwh_calc: total_kwh_calc.toFixed(1)
        }
    }

    const fetchAndFormatChargerData = async (chargerId? : string) => {
        try {
            // Fetch charger histories
            const myChargerHistoriesData: ChargerHistoryAttributes[] = await getMyChargerHistory();

            const { total_charge_price, total_kwh_calc } = historySumWithDateRange(myChargerHistoriesData);

            const formattedChargers = myChargerHistoriesData
                                        .filter((h) => {
                                            if(chargerId && chargerId !== ''){
                                                return h.chargerId === chargerId;
                                            } else {
                                                //If no chargerId is supplied, just show all
                                                return true;
                                            }
                                        })
                                        .sort((a: ChargerHistoryAttributes, b: ChargerHistoryAttributes) => new Date(b.charging_start_time ?? 0).getTime() - new Date(a.charging_start_time ?? 0).getTime())
                                        .map((history) => {
                // Format updatedAt date
                 //const isoDateString = String(history.tblCharger?.updatedAt);
                 const isoDateString = String(history.charging_start_time);
                 const date = new Date(isoDateString);
                const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;

                return {
                    ...history,
                    updatedAt: date,
                    formattedDate,
                };
            });

            setTotalKwh(Number(total_kwh_calc));
            setTotalRevenue(Number(total_charge_price));
            setMyChargerHistory(formattedChargers);
        } catch (e) {
            console.error("Error getting my charger histories", e);
            //notificationService.sendNotification('error', 'You are not logged in! Please log in and try again');
        }
    };

    // Pagination Logic
    const [currentItemsCh, setCurrentItemsCh] = React.useState<ChargerHistoryAttributes[]>([])
    const [currentItemsChargers, setCurrentItemsChargers] = React.useState<ChargerAttributes[]>([])

    // const chargerAssociation = useMemo(() => {
    //     if(myChargerHistory){
    //         const association = myChargerHistory
    //             .map((ch: ChargerHistoryAttributes) => {
    //                 const matchedCharger = myChargersStore?.find((c) => c.uuid === ch.chargerId);
    //                 //console.log(`Matching chargerId: ${ch.chargerId} with uuid in store: ${matchedCharger?.uuid}`);
    //                 return matchedCharger;
    //             });
    //         return association;
    //     }
    // }, [myChargerHistory])

    // Calculate charger associations
    const chargerAssociation: ChargerAttributes[] = useMemo(() => {
        if (myChargerHistory && myChargerHistory.length > 0 && myChargersStore) {
            return myChargerHistory
                .map((ch) => myChargersStore.find((c) => c.uuid === ch.chargerId))
                .filter((matchedCharger): matchedCharger is ChargerAttributes => !!matchedCharger);
        }
        return [];
    }, [myChargerHistory, myChargersStore]);

    useEffect(() => {
        const indexOfLastItemCl = currentPageCl * itemsPerPage;
        const indexOfFirstItemCl = indexOfLastItemCl - itemsPerPage;
        const indexOfLastItemSh = currentPageSh * itemsPerPage;
        const indexOfFirstItemSh = indexOfLastItemSh - itemsPerPage;
        if(myChargerHistory){
            
            const filteredChargerHistory = filterByDateRange(customDates.start, customDates.end);
            setCurrentItemsCh(filteredChargerHistory.slice(indexOfFirstItemSh, indexOfLastItemSh));
        }
        if(chargerAssociation){
            setCurrentItemsChargers(myChargersStore.slice(indexOfFirstItemCl, indexOfLastItemCl));
        }
    }, [currentPageCl, currentPageSh, chargerAssociation, myChargerHistory]);

    
    //const currentItemsCh = myChargerHistory.slice(indexOfFirstItem, indexOfLastItem);
    //const currentItemsChargers = chargerAssociation?.slice(indexOfFirstItem, indexOfLastItem);

    const filterByDateRange = (startDate: any, endDate: any) => {
        return myChargerHistory.filter(item => {
            const chargingStartTime = dayjs(item.charging_start_time); // Parse the datetime string from API
            const start = dayjs(startDate, 'MM/DD/YYYY'); // Parse the start date string
            const end = dayjs(endDate, 'MM/DD/YYYY').add(1, 'day').startOf('day'); // Parse the end date string, add one day so that the entire 'end' day is counted
    
            return chargingStartTime.isSameOrAfter(start) && chargingStartTime.isBefore(end);
        });
    };

    React.useEffect(() => {
        const getChargerData = async () => {
            await fetchAndFormatChargerData();
            dispatch(getMyChargers());
        }

        getChargerData();     
    }, []);

    React.useEffect(() => {
        // Automatically calculate totals and apply filters when dates or history change
        const applyFilters = () => {
            const filteredData = filterByDateRange(customDates.start, customDates.end);
            const { total_charge_price, total_kwh_calc } = historySumWithDateRange(filteredData);
    
            setFilteredChargers(filteredData);
            setTotalKwh(Number(total_kwh_calc));
            setTotalRevenue(Number(total_charge_price));
        };
    
        applyFilters();
    }, [customDates, myChargerHistory]);

    const filterDate = (setDates: any) => {
        setCustomDates({start: setDates.start, end: setDates.end});
        let filteredChargerList;

        filteredChargerList = filterByDateRange(setDates.start, setDates.end);

        setFilteredChargers(filteredChargerList);  // Update state with filtered data
        setFilterModalVisible(!filterModalVisible);
        notificationService.sendNotification('success', `Date Filter Applied`);
    };

    const handleCancel = () => {
        setFilterModalVisible(!filterModalVisible);
        //fetchAndFormatChargerData();  // Reset the filteredChargers to the original list
        //notificationService.sendNotification('success', `Date Filter Removed`);
    };

    const handleShowHistoryPress = async (chargerId: string) => {
        const matchedChargerName = myChargersStore?.find((c) => c.uuid === chargerId);
        setChargeHistoryFilter(matchedChargerName?.name ?? '');
        if(chargerId !== ''){
            setCurrentPageSh(1); //set the current page on the filter to 1
        }
        await fetchAndFormatChargerData(chargerId);
        //await retrieveChargingHistory();
        setView('history');
    }

    const handleChargerListPress = async () => {
        setChargeHistoryFilter('');
        //await fetchAndFormatChargerData();
        setView('list');
    }

    const handleClearFilter = async () => {
        setChargeHistoryFilter('');
        await fetchAndFormatChargerData('');
    }

    const callGetMyChargers = route?.params?.state;

    const handleFilter = () => {
        setFilterModalVisible(!filterModalVisible)
    }

    const registerCharger = () => {
        navigation.navigate("ChargerValidation")
    }

    const editCharger = (chargerDetails:any) => {
        navigation.navigate("AddModifyCharger",{
            chargerDetails: chargerDetails,
            editCharger: true,
            state: callGetMyChargers
        })
    }

    const handleCustomDatePicker = (date: string, pickerType: 'start' | 'end') => {
        setCustomDates(prev => ({ ...prev, [pickerType]: date }));
    };

    return (
        <SafeAreaView style={styles.safeArea}>
            {/* Static Top Section */}
            <View style={[styles.staticTop, { height: 200 }]}>
              <MyChargerHistoryAtAGlance
                handleFilter={handleFilter}
                customDates={customDates}
                filteredChargers={filteredChargers}
                totalKwh={totalKwh}
                totalRevenue={totalRevenue}
              />
    
              <View style={styles.line} />
    
              <View style={styles.selectionContainer}>
                <Pressable onPress={handleChargerListPress}>
                  <Text
                    style={[
                      styles.selectionText,
                      view === 'list' ? styles.activeSelection : styles.inactiveSelection,
                    ]}
                  >
                    Charger List
                  </Text>
                </Pressable>
                <Pressable onPress={() => handleShowHistoryPress('')}>
                  <Text
                    style={[
                      styles.selectionText,
                      view === 'history' ? styles.activeSelection : styles.inactiveSelection,
                    ]}
                  >
                    Show History
                  </Text>
                </Pressable>
                <Pressable onPress={registerCharger}>
                  <Text style={styles.registerButton}>Register a Charger</Text>
                </Pressable>
              </View>
    
              {chargeHistoryFilter !== '' && (
                <View style={styles.filterContainer}>
                  <Pressable
                    onPress={handleClearFilter}
                    style={styles.clearButton}
                  >
                    <Text style={styles.clearButtonText}>X</Text>
                  </Pressable>
                  <Text style={styles.filterText}>
                    {`Currently filtering ${chargeHistoryFilter}`}
                  </Text>
                </View>
              )}
            </View>
    
            {/* Scrollable List Section */}
            <ScrollView
                style={[styles.scrollView, {marginTop: chargeHistoryFilter === '' ? 415 : 465}]}
                showsVerticalScrollIndicator={true}
            >
                <View style={styles.listContainer}>
                    {view === 'list' && currentItemsChargers.length > 0 ? (
                    currentItemsChargers.map((data, index) => (
                        <MyChargerInfoCard
                            index={index}
                            data={data}
                            editCharger={editCharger}
                            showHistory={handleShowHistoryPress}
                            key={`chargerInfoCard${index}`}
                        />
                        // <Text>{JSON.stringify(data)}</Text>
                    ))
                    ) : (
                    view === 'history' && currentItemsCh.length > 0 ? (
                        currentItemsCh.map((ch, index) => (
                            <ChargeHistoryCard
                                index={index}
                                chargerHistory={ch}
                                charger={chargerAssociation[index]}
                                key={`chargeHistoryCard${index}`}
                                isPressable={false}
                            />
                        ))
                    ) : (
                        <Text style={styles.noItemsText}>No items to display.</Text>
                    )
                    )}
                </View>
    
               {/* Pagination */}
                <View style={styles.paginationContainer}>
                    {Array.from({
                        length: Math.ceil(
                            view === 'list'
                                ? myChargersStore.length / itemsPerPage
                                : filterByDateRange(customDates.start, customDates.end).length / itemsPerPage
                        ),
                    }).map((_, i) => (
                        <Pressable key={i} onPress={() => handlePageChange(i + 1)}>
                            <Text
                            style={
                                (view === 'list' 
                                    ? currentPageCl 
                                    : currentPageSh) === i + 1
                                    ? styles.activePage
                                    : styles.inactivePage
                            }
                            >
                            {i + 1}
                            </Text>
                        </Pressable>
                    ))}
                </View>
            </ScrollView>
            {/* modal */}
            <Modal
                animationType="fade"
                transparent={true}
                visible={filterModalVisible}
                onRequestClose={() => {
                    setFilterModalVisible(!filterModalVisible);
                }}
            >
                <TouchableWithoutFeedback
                    onPress={handleCancel} // Trigger "cancel" when clicking outside modal
                >
                    <DateFilterModal
                        selectedOption={selectedOption}
                        setSelectedOption={handleSetSelectedOption}
                        filterDate={filterDate}
                        handleCancel={handleCancel}
                        //handleCustomDatePicker={handleCustomDatePicker}
                        customDates={customDates}
                    />
                </TouchableWithoutFeedback>
            </Modal>
        </SafeAreaView>
      );
}

const styles = StyleSheet.create({
    scrollView: {
        flex: 1,
    },
    scrollViewContent: {
        paddingBottom: 100, // Ensure enough padding for pagination
    },
    safeArea: {
      flex: 1,
      backgroundColor: '#0B1E1D',
    },
    staticTop: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: '#1A1A1A',
      zIndex: 10,
      padding: 10,
    },
    line: {
      borderBottomColor: '#636363',
      borderBottomWidth: 1,
      borderStyle: 'dashed',
      marginVertical: 20,
    },
    selectionContainer: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    selectionText: {
      fontSize: 16,
      fontWeight: 'bold',
      marginHorizontal: 10,
    },
    activeSelection: {
      color: 'white',
    },
    inactiveSelection: {
      color: '#aaa',
    },
    registerButton: {
      color: '#fff',
      fontWeight: 'bold',
      backgroundColor: '#39BEB780',
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 9999,
    },
    filterContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      padding: 10,
      borderWidth: 1,
      borderColor: 'white',
      borderRadius: 9999,
    },
    clearButton: {
      backgroundColor: 'white',
      width: 24,
      height: 24,
      borderRadius: 12,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 10,
    },
    clearButtonText: {
      color: 'black',
      fontWeight: 'bold',
    },
    filterText: {
      color: 'white',
      fontSize: 14,
      fontWeight: 'bold',
    },
    listContainer: {
      //marginTop: 10,
      //padding: 10,
        paddingHorizontal: 16, // Add horizontal padding for content alignment
    },
    noHistoryText: {
      color: 'white',
      fontSize: 16,
      textAlign: 'center',
      marginTop: 20,
    },
    paginationContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      paddingVertical: 10,
    },
    activePage: {
      color: 'white',
      marginHorizontal: 8,
      fontWeight: 'bold',
    },
    inactivePage: {
      color: '#aaa',
      marginHorizontal: 8,
    },
    noItemsText: {
        color: 'white',
        textAlign: 'center',
        marginVertical: 20,
    },
  });