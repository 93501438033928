import ContentLoader, {Rect} from 'react-content-loader/native';

const LoadingPlaceholder = () => (
    <ContentLoader
      speed={2}
      width={100}
      height={17}
      viewBox="0 0 100 17"
      backgroundColor="#8d8e90"
      foregroundColor="#102928"
    >
      <Rect x="0" y="0" rx="3" ry="3" width="20" height="8" />
    </ContentLoader>
  );

export default LoadingPlaceholder;