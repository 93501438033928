import { getDateInLocalSeconds, getDateInUtcSeconds } from "../components/common/utils/date-time-conversions";
import { PrepareApiProcedure } from "../components/common/utils/prepare-api-procedure";
import { ChargerHistoryAttributes } from "../models/charger-history-model";
import { ChargerAttributes } from "../models/charger-model";
import { ApiRequest, fetchDataFromAPI, fetchDataFromAPITyped } from "./api-requests";
import { notificationService } from "./notification-service";

// FUNCTION: getMyChargingHistory
// PURPOSE: Retrieve the charging history for chargers that I have used
export const getMyChargerHistory = async () => {
    try {
        // Fetch charger histories
        const apiQuery = PrepareApiProcedure(
            'charger/getMyChargerHistories/',
            'GET',
            'chargerHistory',
            ''
        );
        const chargingHistoryData = await fetchDataFromAPI(apiQuery);
        return chargingHistoryData;

    } catch (error) {
        console.log(error.message);
        notificationService.sendNotification('error', `Error retrieving your charging history! ${error.message}`);
    }
}

export const getChargerHistoryByOrder = async (orderId: string) => {
    const apiRequest: ApiRequest = {
        route: 'charger/chargerHistoryByTime',
        request: {
            method: 'POST',
            entity: 'chargerHistory',
            body: {
                orderId: orderId
            }
        }
    }
    return fetchDataFromAPITyped<ChargerHistoryAttributes>(apiRequest);
}

// * FUNCTION: getChargerHistorybyUser
// * PURPOSE: retrieve a user's charging history
export const getChargingHistoryByUser = async (startTime?: Date, endTime?: Date) => {
    try{
        const apiQuery = PrepareApiProcedure(
            `charger/chargerHistoryByUser`,
            'POST',
            'chargerHistory',
            {
                timeRangeFrom: startTime ? getDateInUtcSeconds(startTime) : 0,
                timeRangeTo: endTime ? getDateInUtcSeconds(endTime) : 0,
            }
        );
        const myChargingHistory = await fetchDataFromAPI(apiQuery);
        return myChargingHistory;
    } catch (error) {
        console.error("Error getting user charging history", error);
        notificationService.sendNotification("error", "Error retrieving your charging history");
    }
}