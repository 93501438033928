import React, { useState } from 'react';
import { View, Animated, StyleSheet, ActivityIndicator, Text, Dimensions } from 'react-native';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import AntDesign from '@expo/vector-icons/AntDesign';

interface SlideToActivateButtonProps {
  onComplete: () => Promise<void>;
  disabled?: boolean;
  backgroundColor?: string;
  children?: React.ReactNode;
}

const SlideToActivateButton: React.FC<SlideToActivateButtonProps> = ({
    onComplete,
    disabled = false,
    backgroundColor = '#3FC67D',
    children,
  }) => {
    const [isLoading, setIsLoading] = useState(false);
    const translateX = useState(new Animated.Value(0))[0];
    const animatedValue = useState(new Animated.Value(1))[0];
  
    const SCREEN_WIDTH = Dimensions.get('window').width; // Screen width
    const SLIDE_WIDTH = Math.min(SCREEN_WIDTH * 0.9, 400); // Button width: 90% of the screen width
    const SLIDER_WIDTH = 60; // Slider (thumb) width
    const SLIDE_THRESHOLD = SLIDE_WIDTH - SLIDER_WIDTH; // Maximum slide distance
  
    const handleSlideComplete = async () => {
      if (isLoading || disabled) return; // Prevent duplicate actions
      setIsLoading(true);
  
      try {
        await onComplete(); // Trigger the onComplete callback
      } catch (error) {
        console.error('Error during slide action:', error);
      } finally {
        // Reset the slider and loading state
        Animated.timing(translateX, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }).start(() => setIsLoading(false));
      }
    };
  
    const handleGestureEnd = (event: any) => {
      const { translationX } = event.nativeEvent;
  
      // If the slider has moved enough to trigger activation
      if (translationX >= SLIDE_THRESHOLD) {
        handleSlideComplete();
      } else {
        // Reset slider position if not far enough
        Animated.spring(translateX, {
          toValue: 0,
          useNativeDriver: true,
        }).start();
      }
    };
  
    const handleGesture = Animated.event(
      [
        {
          nativeEvent: { translationX: translateX },
        },
      ],
      {
        useNativeDriver: true,
        listener: (event) => {
          const translationX = Math.min(
            Math.max(0, event.nativeEvent.translationX), // Clamp value within bounds
            SLIDE_THRESHOLD
          );
          translateX.setValue(translationX);
        },
      }
    );
  
    const fadeToDisabledColor = () => {
      Animated.timing(animatedValue, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    };
  
    const fadeToEnabledColor = () => {
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }).start();
    };
  
    if (disabled || isLoading) {
      fadeToDisabledColor();
    } else {
      fadeToEnabledColor();
    }
  
    const interpolatedBackgroundColor = animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['#bdc3c7', backgroundColor], // Disabled to enabled color
    });
  
    return (
      <Animated.View
        style={[
          styles.container,
          { backgroundColor: interpolatedBackgroundColor, width: SLIDE_WIDTH },
        ]}
      >
        <View style={styles.labelContainer}>
          {isLoading ? (
            <ActivityIndicator size="small" color="#fff" style={styles.spinner} />
          ) : (
            <Text style={styles.text}>{children}</Text>
          )}
        </View>
        <PanGestureHandler
          onGestureEvent={handleGesture}
          onHandlerStateChange={(event) => {
            if (event.nativeEvent.state === State.END) {
              handleGestureEnd(event);
            }
          }}
        >
          <Animated.View
            style={[
              styles.slider,
              {
                transform: [
                  {
                    translateX: translateX.interpolate({
                      inputRange: [0, SLIDE_THRESHOLD],
                      outputRange: [0, SLIDE_THRESHOLD],
                      extrapolate: 'clamp',
                    }),
                  },
                ],
              },
            ]}
          >
            <View style={styles.sliderContent}>
                <AntDesign name="rightcircle" size={24} color="#3FC67D" />
          </View>
          </Animated.View>
        </PanGestureHandler>
      </Animated.View>
    );
  };

const styles = StyleSheet.create({
    container: {
        height: 60,
        borderRadius: 24,
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        marginVertical: 10,
        marginHorizontal: 20,
        alignSelf: 'center',
      },
      labelContainer: {
        position: 'absolute',
        alignSelf: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      },
      text: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
      },
      spinner: {
        marginRight: 8,
      },
      sliderContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 10,
      },
      slider: {
        width: 60,
        height: 60,
        borderRadius: 20,
        backgroundColor: '#0B1E1D',
        justifyContent: 'center',
        alignItems: 'center',
      },
      sliderContent: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      },
      sliderText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#39BEB7',
      },
  });

export default SlideToActivateButton;