import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { createUser } from '../models/login-register-model';
import { loginUser, registerUser, updateUser, validateUserToken } from '../store/actions/user-actions';
import { notificationService } from '../services/notification-service';
import { IconButton } from 'react-native-paper';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/MeanderStack';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import { createUserSession, UserSessionAttributes } from '../models/user-session-model';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { setSession } from '../store/actions/user-session';
import GoogleAuthButton from '../components/google-auth-button';
import AppleAuthButton from '../components/AppleAuthButton';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { storeUserSession } from '../services/user-session-service';
import { meanErrors } from '../components/common/utils/mean-errors';
import LoadingButton from '../components/LoadingButton';

const { width, height } = Dimensions.get('window');

type Props = NativeStackScreenProps<RootStackParamList, 'CreateAccount'>
interface CreateAccountFormProps {
    formEmail?: string;
    navigation?: any;
    handleCreateAccount?: () => void;
    showAltLogins?: boolean;
  }
  

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({formEmail, navigation, handleCreateAccount, showAltLogins = false}) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: formEmail ?? '',
        confirmEmail: '',
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [isPasswordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);

    const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session);

    const dispatch: AppDispatch = useDispatch();

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateDynamicForm = (updatedFormData: typeof formData): Record<string, string> => {
        const newErrors: Record<string, string> = {};
    
        if (!updatedFormData.firstName && updatedFormData.firstName !== undefined)
            newErrors.name = 'Please enter your name.';
        if (!updatedFormData.lastName && updatedFormData.lastName !== undefined)
            newErrors.name = 'Please enter your name.';
    
        if (updatedFormData.email) {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(updatedFormData.email)) {
                newErrors.email = 'Please enter a valid email address.';
            }
            if (updatedFormData.confirmEmail && updatedFormData.email !== updatedFormData.confirmEmail) {
                newErrors.confirmEmail = 'Email addresses do not match.';
            }
        }
    
        if (updatedFormData.password) {
            if (!updatedFormData.confirmPassword || updatedFormData.password !== updatedFormData.confirmPassword) {
                newErrors.confirmPassword = 'Passwords do not match.';
            }
        }
    
        return newErrors;
    };

    const newUserSession = async (newUserData: any) => {
        try {
            if (newUserData?.status === 201) {
                notificationService.sendNotification('success', 'Success! Your account is registered');
                const getIpAddress = await getIpAddressFunc();
                const loginObj = createUserSession({
                    email: formData.email,
                    password: formData.password,
                    IpAddress: getIpAddress || '1.1.1.1',
                });
                const userToken = await loginUser(loginObj);
                const sessionData = await validateUserToken();
                dispatch(setSession(sessionData));
                handleCreateAccount?.();
                navigation.navigate('WelcomeScreen');
            } else {
                //notificationService.sendNotification('error', 'There was an issue while registering, please try again');
            }
        } catch (error) {
            notificationService.sendNotification('error', 'An unexpected error occurred.');
        }
    };

    useEffect(() => {
        const verifySession = async () => {
            const sessionVerify: UserSessionAttributes = await validateUserToken();
            if (sessionVerify.msg.toLowerCase() != 'ok') {
                AsyncStorage.clear()
            } else {
                await AsyncStorage.setItem("token", sessionVerify.access_token);
                await AsyncStorage.setItem("IpAddress", sessionVerify.IpAddress);
                await AsyncStorage.setItem("email", sessionVerify.email);
                await AsyncStorage.setItem("role", sessionVerify.role);
                await AsyncStorage.setItem("userId", sessionVerify.id);
                await storeUserSession(sessionVerify);
                newUserSession({email: sessionVerify.email, status:200});
            }
        }
        
        if(session && session.role.toLowerCase() !== 'guest'){
            verifySession();
        }
    }, [session])

    const handleInputChange = (field: string, value: string) => {
        setFormData((prev) => {
            const updatedFormData = { ...prev, [field]: value };
    
            // Perform validation dynamically for the updated formData
            const updatedErrors = validateDynamicForm(updatedFormData);
            setErrors(updatedErrors);
    
            return updatedFormData;
        });
    };

    const signUp = async (): Promise<boolean> => {
        if (!validateDynamicForm(formData)) return false; // Return false if validation fails.
    
        try {
            const registerObj = createUser({
                email: formData.email,
                password: formData.password,
                first_name: formData.firstName,
                last_name: formData.lastName,
            });
    
            if (session?.role === 'guest') {
                await dispatch(updateUser(session, formData.firstName, formData.lastName, formData.email, formData.password));
                return true;
            } else {
                const resp = await registerUser(registerObj);
    
                if (resp?.errObj) {
                    notificationService.sendNotification('error', resp.errObj.message);
                    return false; // Return false if registration fails.
                } else {
                    await newUserSession(resp);
                    return true; // Return true if registration is successful.
                }
            }
        } catch (error) {
            //notificationService.sendNotification('error', 'An error occurred while signing up.');
            return false; // Return false if an exception occurs.
        }
    
        return false; // Default return value for unexpected scenarios.
    };

    const handleSubmit = async () => {
        const signupSuccess = await signUp();
        if (signupSuccess) {
            navigation.navigate('MyAccount'); // Navigate to MyAccount if signup succeeds.
        } else {
            notificationService.sendNotification('error', 'Signup failed. Please try again.');
        }
    };

    useEffect(() => {
        const verifySession = async () => {
            try {
                const sessionVerify = await validateUserToken();
                if (sessionVerify.msg.toLowerCase() !== 'ok') {
                    AsyncStorage.clear();
                } else {
                    await AsyncStorage.multiSet([
                        ['token', sessionVerify.access_token],
                        ['IpAddress', sessionVerify.IpAddress],
                        ['email', sessionVerify.email],
                        ['role', sessionVerify.role],
                        ['userId', sessionVerify.id],
                    ]);
                    storeUserSession(sessionVerify);
                    dispatch(setSession(sessionVerify));
                }
            } catch {
                notificationService.sendNotification('error', 'Session validation failed.');
            }
        };

        if (session && session.role.toLowerCase() !== 'guest') {
            verifySession();
        }
    }, [dispatch, session]);

    return (
        <SafeAreaView>
            <View style={[styles.containerBgColor, { height: height}]}>
                <ScrollView>
                    <View className='p-4'>
                        <Text className='text-2xl font-bold text-center text-white'>Create an account</Text>
                        <Text style={styles.subHeaderText} className='mb-5 text-base font-normal text-center'>
                            You will be able to log in with your email in the future, using the password you set below.
                        </Text>

                        <View>
                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>First Name</Text>
                            <TextInput
                                placeholder="Enter first name"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={formData.firstName}
                                onChangeText={(value: string) => handleInputChange('firstName', value)}
                            />
                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Last Name</Text>
                            <TextInput
                                placeholder="Enter last name"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={formData.lastName}
                                onChangeText={(value: string) => handleInputChange('lastName', value)}
                            />
                            {errors.name ? <Text style={styles.errorText}>{errors.name}</Text> : null}

                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Email</Text>
                            <TextInput
                                placeholder="Enter your email"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={formData.email}
                                autoCorrect={false}
                                autoComplete="new-email"
                                textContentType='none'
                                onChangeText={(value) => handleInputChange('email', value)}
                            />
                            {errors.email ? <Text style={styles.errorText}>{errors.email}</Text> : null}

                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Confirm Email</Text>
                            <TextInput
                                placeholder="Confirm your email"
                                placeholderTextColor={'#8D8E90'}
                                style={[styles.BgColor, { borderRadius: 25 }]}
                                className="w-full p-4 text-white"
                                value={formData.confirmEmail}
                                autoCorrect={false}
                                autoComplete="off"
                                textContentType='none'
                                onChangeText={(value) => handleInputChange('confirmEmail', value)}
                            />
                            {errors.confirmEmail ? <Text style={styles.errorText}>{errors.confirmEmail}</Text> : null}

                            
                            <View>
                                <Text className='pt-3 pb-2 text-base font-semibold text-white'>Create a Password</Text>
                                <TextInput
                                    placeholder="Enter your password"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor, { borderRadius: 25 }]}
                                    className="w-full p-4 text-white"
                                    secureTextEntry={!isPasswordVisible}
                                    value={formData.password}
                                    autoCorrect={false}
                                    autoComplete="new-password"
                                    textContentType='none'
                                    onChangeText={(value) => handleInputChange('password', value)}
                                />
                                <IconButton
                                    icon={(props) => (
                                        <MaterialCommunityIcons
                                        name={isPasswordVisible ? "eye" : "eye-off"}
                                        {...props}
                                        style={{ color: "#01556F" }}
                                        />
                                    )}
                                    onPress={() => setPasswordVisible(!isPasswordVisible)}
                                    style={styles.createPasswordIcon}
                                />
                            </View>

                            {errors.password ? <Text style={styles.errorText}>{errors.password}</Text> : null}

                            <Text className='pt-3 pb-2 text-base font-semibold text-white'>Confirm Password</Text>
                            <View>
                                <TextInput
                                    placeholder="Confirm your password"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor, { borderRadius: 25 }]}
                                    className="w-full p-4 text-white"
                                    secureTextEntry={!isPasswordVisibleConfirm}
                                    value={formData.confirmPassword}
                                    autoCorrect={false}
                                    autoComplete="off"
                                    textContentType='none'
                                    onChangeText={(value) => handleInputChange('confirmPassword', value)}
                                />
                                <IconButton
                                    icon={(props) => (
                                        <MaterialCommunityIcons
                                        name={isPasswordVisibleConfirm ? "eye" : "eye-off"}
                                        {...props}
                                        style={{ color: "#01556F" }}
                                        />
                                    )}
                                    onPress={() => setPasswordVisibleConfirm(!isPasswordVisibleConfirm)}
                                    style={styles.createPasswordIconConfirm}
                                />
                            </View>
                            {errors.confirmPassword ? <Text style={styles.errorText}>{errors.confirmPassword}</Text> : null}

                            {/* <Pressable 
                                onPress={handleSubmit} 
                                style={styles.button} 
                                className='p-4 mt-5 text-center rounded-full'>
                                <Text className='text-lg font-medium text-center text-white'>Create my account</Text>
                            </Pressable> */}
                            <View style={{marginTop: 20}}>
                                <LoadingButton
                                    onPress={handleSubmit}
                                    className='p-4 mt-5 text-center rounded-full'
                                    backgroundColor={styles.button.backgroundColor}
                                >
                                    Create My Account
                                </LoadingButton>
                            </View>


                            {/* {showAltLogins &&
                                <View>
                                    <GoogleAuthButton 
                                        navigation={navigation}
                                        RBRef = {null}
                                        destination={null}
                                    />
                                    <AppleAuthButton />
                                </View>
                            } */}
                        </View>
                    </View>
                </ScrollView>
            </View>
        </SafeAreaView>
    );
};

export default CreateAccountForm;

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    subHeaderText: {
        color: '#8D8E90',
    },
    BgColor: {
        backgroundColor: '#292D33',
    },
    errorText: {
        color: '#f38787',
        fontSize: 14,
        marginTop: 4,
    },
    button: {
        backgroundColor: '#39BEB7',
    },
    createPasswordIcon: {
        position: 'absolute',
        top: 45,
        alignSelf: 'flex-end' 
    },
    createPasswordIconConfirm:{
        position: 'absolute',
        alignSelf: 'flex-end'
    }
});


