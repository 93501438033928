import React from 'react';
import { View, Platform, Dimensions, Image, Pressable, Modal, Keyboard, TouchableWithoutFeedback} from 'react-native';
import { modalStyles } from '../styles/ModalStyles';
import { styles } from '../styles/ChargerStyles';
import { AutocompleteDropdown } from 'react-native-autocomplete-dropdown';
//import { Card, CardTitle, CardContent, CardAction, CardButton } from 'react-native-material-cards';
import { Button, Card, Text } from 'react-native-paper';
import { ChargerAttributes, createEmptyCharger } from '../models/charger-model';
import { ChargerHistoryAttributes } from '../models/charger-history-model';

import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

//define navigation stack param list
type ChargerStackParamList = {
    ManageChargers: {
        myChargers: ChargerAttributes[];
        myChargerHistories: ChargerHistoryAttributes[];
    };
};

//specify route and navigation prop types
type ManageChargerRouteProp = RouteProp<ChargerStackParamList, 'ManageChargers'>;
type ManageChargerNavigationProp = NativeStackNavigationProp<ChargerStackParamList, 'ManageChargers'>;

//Props interface to combine route and navigation
interface ManageChargerProps {
    route: ManageChargerRouteProp;
    navigation: ManageChargerNavigationProp;
}

const ManageCharger: React.FC<ManageChargerProps> = ({ route, navigation }) => {

    const [selectedCharger, setSelectedCharger ] = React.useState<ChargerAttributes | undefined>(createEmptyCharger());

    if(route && route.params ){
        const myChargers: ChargerAttributes[] = route.params.myChargers;
        const myChargerHistories: ChargerHistoryAttributes[] = route.params.myChargerHistories;

        const dropdownSet = myChargers.map((charger) => {
            return(
                {
                    id: charger.uuid,
                    title: `${charger.name}: ${charger.unit_serial}`,
                }
            );
        })
        console.log("dropdown set:", dropdownSet);

        const selectCharger = (item: {id: string} | null) => {
            if(item !== null){
                console.log("selected item: ", item)
                const chargerId = item.id;
                const charger = myChargers.find(charger => charger.uuid === chargerId)
                if(charger){
                    setSelectedCharger(charger)
                } else {
                    console.error('No charger found with ID:', chargerId);
                }
            } else {
                setSelectedCharger(undefined);
            }
        }

        const sumHistoryByElement = <T extends keyof ChargerHistoryAttributes>(
            chargerId: string,
            data: ChargerHistoryAttributes[],
            key: T
        ):number  => {
            return data.reduce((accumulator, c:ChargerHistoryAttributes) => {
                if(c.chargerId === chargerId){
                    const value = c[key];
                    if(value !== null && typeof value === 'string'){
                        const parsedValue = parseFloat(value);
                        if (!isNaN(parsedValue)){
                            return accumulator + parsedValue;
                        }
                    } else if (value !== null && typeof value === 'number'){
                        //sometimes, the charge time could be null if the charger is currently charging
                        return accumulator + value;
                    }
                }
                return accumulator; //return the accumulated value if charge time is null
            }, 0); //start accumulating from 0
        }
        
        const formatText = (text: string) => {
            //return text.replace(/[^+\d]/g, '');
            //use this for input control if necessary
            return text;
        }

        const handlePress = () => {
            // Hide the keyboard
            Keyboard.dismiss();
        };

        //console.log(dropdownSet);

        return (
            <View style={styles.ChargerScreen}>
                <TouchableWithoutFeedback onPress={handlePress}>
                    <View style={modalStyles.modalContainer}>
                        <View style={modalStyles.modalContent}>
                            <View
                                style={[
                                    { flex: 0, flexDirection: 'row', alignItems:'center', position:'relative'},
                                    Platform.select({ ios: {zIndex: 999}})
                                ]}>
                                <AutocompleteDropdown
                                    clearOnFocus={false}
                                    closeOnBlur={true}
                                    closeOnSubmit={false}
                                    initialValue={{id: "1"}}
                                    onSelectItem={(item) => selectCharger(item ? { id: item.id } : null)}
                                    dataSet={dropdownSet}
                                    suggestionsListMaxHeight={Dimensions.get('window').height * 0.4}
                                    textInputProps={{
                                        placeholder: 'Start typing the Serial #',
                                        autoCorrect: false,
                                        autoCapitalize: 'none',
                                        style: {
                                            borderRadius: 25,
                                            backgroundColor: '#383b42',
                                            color: '#FFF',
                                            paddingLeft: 18,
                                            zIndex: 999,
                                        }
                                    }}
                                    rightButtonsContainerStyle={{
                                        right: 8,
                                        height: 30,
                                        alignSelf: 'center',
                                        zIndex: 999,
                                    }}
                                    inputContainerStyle={{
                                        backgroundColor: '#383b42',
                                        borderRadius: 25,
                                        zIndex: 999,
                                        
                                    }}
                                    suggestionsListContainerStyle={{
                                        backgroundColor: '#FFF',
                                        zIndex: 999,
                                        flex: 1,
                                        width: '90%',
                                        marginBottom: 16,
                                        justifyContent: 'center',
                                        marginTop: 0,
                                        elevation: 3,
                                        paddingHorizontal: 15,
                                        top:0,
                                    }}
                                    containerStyle={{ flexGrow: 1, flexShrink: 1, zIndex:999}}
                                    inputHeight={50}
                                    showChevron={false}
                                />
                            </View>
                                {selectedCharger &&
                                    <View>
                                        <Card>
                                            <Card.Title
                                                title={`${selectedCharger?.name}`}
                                                subtitle={`This charger is currently ${selectedCharger.isOccupied === 1 ? "in use" : "available"}!`}
                                            />
                                            <Card.Content>
                                                <Text variant="titleLarge">{`Total Earned: $${sumHistoryByElement(selectedCharger.uuid, myChargerHistories, "total_charge_price").toFixed(2)}`}</Text>
                                                <Text variant="bodyMedium">{`Total Time: ${(sumHistoryByElement(selectedCharger.uuid, myChargerHistories, "total_charge_time")/(60*60)).toFixed(2)} hours`}</Text>
                                            </Card.Content>
                                            <Card.Actions>
                                                <Button
                                                    onPress={() => {}}
                                                    color="blue"
                                                > Modify </Button>
                                            </Card.Actions>
                                        </Card>
                                    </View>
                                }
                            <View>
                                <Pressable 
                                    style={styles.button} 
                                    onPress={()=> {
                                        navigation.navigate('ChargerScreen');
                                    }}
                                >
                                    <Text style={styles.buttonText}> {`Back`} </Text>
                                </Pressable>
                            </View>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        )
    }
}

export default ManageCharger;