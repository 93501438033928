import React, { useEffect, useState } from 'react';

import { SafeAreaView, View,Text, ScrollView, StyleSheet, Dimensions, TextInput, Image, Pressable } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { IconButton } from 'react-native-paper';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/MeanderStack';
import { UserSessionAttributes } from '../models/user-session-model';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { notificationService } from '../services/notification-service';
import { updateUser } from '../store/actions/user-actions';

const { width, height } = Dimensions.get('window');

type Props = NativeStackScreenProps<RootStackParamList, 'AccountInfo'>

const AccountInfo: React.FC<Props> = ({navigation, route}) => {
    const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
    const [isPasswordVisibleConfirm, setPasswordVisibleConfirm] = useState<boolean>(false);
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({
        email: "",
        password: "",
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [accountInfo, setAccountInfo] = useState({
        first_name: "",
        last_name: "",
        email: "",
    })
    const dispatch:AppDispatch = useDispatch();

    const session: UserSessionAttributes | undefined = useSelector((state: RootState) => state.user.session)

    const isChangePassword = route?.params?.changePassword

    const handleSubmit = async () => {
        if (session) {
            // Filter out empty strings
            const filteredAccountInfo = Object.fromEntries(
              Object.entries(accountInfo).filter(([_, value]) => value !== "")
            );
        
            // Only pass password if it's not an empty string
            const passwordToPass = password !== "" ? password : undefined;
        
            // Call updateUser only with defined and non-empty parameters
            const updated = await dispatch(updateUser(
              session, 
              filteredAccountInfo.first_name, 
              filteredAccountInfo.last_name, 
              filteredAccountInfo.email, 
              passwordToPass
            ));
            if (updated) {
                notificationService.sendNotification("success", "Your account was updated!");
            } else {
                notificationService.sendNotification("error", "Error: Your account was not updated");
            }
            navigation.navigate("MyAccount");
          } else {
            notificationService.sendNotification("error", "Invalid Session. Please log in again");
          }

    }

    useEffect(() => {
        //we should not have an undefined session state here, throw the user out if they manage to get here without a valid session
        if(!session){
            notificationService.sendNotification("error", "You do not have a valid session");
            navigation.navigate("WelcomeScreen");
        } else {
            setAccountInfo({
                first_name: session.first_name,
                last_name: session.last_name,
                email: session.email
            })
        }

    }, [])
    
    return(
        <SafeAreaView style={styles.safeArea}>
            <View style={[styles.containerBgColor, { height: height - 65 }]}>
                <ScrollView contentContainerStyle={styles.scrollViewContent}>

                    {isChangePassword ? (
                        <View>
                            <Text style={styles.mainHead}> Change Password </Text>
                            <View className='flex items-center mt-6'>
                                <Image 
                                    source={require('../../assets/meanderlogowhite_120x120.png')}
                                    style={{ width: 100, height: 100, alignItems: 'center' }}
                                />
                            </View>
                            <View>
                                <View>
                                    <Text className="pt-3 pb-2 text-base font-semibold text-white">Create New Password</Text>
                                    <TextInput
                                        placeholder="Create New Password"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor1, { borderRadius: 16 }]}
                                        className="w-full p-3 text-white"

                                        onChange={(e) => {
                                        setPassword(e.nativeEvent.text);
                                        setErrors({ ...errors, password: "" });
                                        }}
                                        value={password}
                                        keyboardType="visible-password"
                                        secureTextEntry={!isPasswordVisible}
                                    />
                                    <IconButton
                                        icon={(props) => (
                                            <MaterialCommunityIcons
                                            name={isPasswordVisible ? "eye" : "eye-off"}
                                            {...props}
                                            style={{ color: "#01556F" }}
                                            />
                                        )}
                                        onPress={() => setPasswordVisible(!isPasswordVisible)}
                                        style={{ position: 'absolute',
                                            right: 0,
                                            top: 39}}
                                    />
                                </View>
                                
                                <View>
                                    <Text className="pt-3 pb-2 text-base font-semibold text-white">Confirm New Password</Text>
                                    <TextInput 
                                        placeholder="Confirm New Password"
                                        placeholderTextColor={'#8D8E90'}
                                        style={[styles.BgColor1, { borderRadius: 16 }]}
                                        className="w-full p-3 text-white"

                                        secureTextEntry={!isPasswordVisibleConfirm}
                                        value={confirmPassword}
                                        onChange={(e) => {
                                            setConfirmPassword(e.nativeEvent.text);
                                            setErrors({ ...errors, confirmPassword: "" });
                                            }}
                                    />

                                    <IconButton
                                        icon={(props) => (
                                            <MaterialCommunityIcons
                                            name={isPasswordVisibleConfirm ? "eye" : "eye-off"}
                                            {...props}
                                            style={{ color: "#01556F" }}
                                            />
                                        )}
                                        onPress={() => setPasswordVisibleConfirm(!isPasswordVisibleConfirm)}
                                        style={{ position: 'absolute',
                                            right: 0,
                                            top: 39}}
                                    />
                                </View>
                            </View>
                        </View>
                    ): 
                    (
                        <View>
                            <Text style={styles.mainHead}> Update Account Information </Text>
                            <View className='flex items-center mt-6'>
                                <Image 
                                    source={require('../../assets/meanderlogowhite_120x120.png')}
                                    style={{ width: 100, height: 100, alignItems: 'center' }}
                                />
                            </View>
                            <View>
                                <Text className="pt-3 pb-2 text-base font-semibold text-white">First Name</Text>
                                <TextInput 
                                    placeholder="Enter your first name"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor1, { borderRadius: 16 }]}
                                    className="w-full p-3 text-white"
                                    value={accountInfo.first_name}
                                    onChange={(e) => {
                                        setAccountInfo({ ...accountInfo, first_name: e.nativeEvent.text });
                                        }}
                                />
                                <Text className="pt-3 pb-2 text-base font-semibold text-white">Last Name</Text>
                                <TextInput 
                                    placeholder="Enter your last name"
                                    placeholderTextColor={'#8D8E90'}
                                    style={[styles.BgColor1, { borderRadius: 16 }]}
                                    className="w-full p-3 text-white"
                                    value={accountInfo.last_name}
                                    onChange={(e) => {
                                        setAccountInfo({ ...accountInfo, last_name: e.nativeEvent.text });
                                        }}
                                />
                                <Text className="pt-3 pb-2 text-base font-semibold text-white">Email</Text>
                                <TextInput 
                                    placeholder="Enter your email"
                                    placeholderTextColor={'#8D8E90'}                    
                                    style={[styles.BgColor1, { borderRadius: 16 }]}
                                    className="w-full p-3 text-white"
                                    value={accountInfo.email}
                                    onChange={(e) => {
                                        setAccountInfo({ ...accountInfo, email: e.nativeEvent.text });
                                        }}
                                />
                            </View>
                        </View>
                    )}
                </ScrollView>
                {isChangePassword ? (
                    <View style={styles.buttonContainer}>
                        <Pressable onPress={handleSubmit} style={styles.button}>
                            <Text className="text-lg font-medium text-center text-white">Save Password</Text>
                        </Pressable>
                    </View>
                ):
                (
                    <View style={styles.buttonContainer}>
                        <Pressable onPress={handleSubmit} style={styles.button}>
                            <Text className="text-lg font-medium text-center text-white">Save Account Information</Text>
                        </Pressable>
                    </View>
                )}
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#1A1D21',
    },
    containerBgColor: {
        flex: 1,
        backgroundColor: '#1A1D21',
        paddingBottom: 60,
    },
    scrollViewContent: {
        flexGrow: 1,
        padding: 16,
    },
    BgColor1: {
        backgroundColor: '#292D33',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 20,
        left: 16,
        right: 16,
    },
    button: {
        backgroundColor: '#39BEB7',
        padding: 16,
        borderRadius: 9999,
        alignItems: 'center',
    },
    mainHead:{
        color: 'white', 
        fontSize: 18,
        alignSelf: 'center',
        fontWeight: '400'
    }
})

export default AccountInfo;