import { useState } from 'react';
import { View, Text, Pressable, StyleSheet, TextInput, SafeAreaView, Dimensions, Image } from 'react-native';
import RBSheet from "react-native-raw-bottom-sheet";
import GoogleAuthButton from '../components/google-auth-button';
import SignInFieldsOnly from './SignInFieldsOnly';
import { getIpAddressFunc } from '../components/common/utils/ip-address';
import { createUserSession } from '../models/user-session-model';
import { loginUser, validateUserToken } from '../store/actions/user-actions';
import { useDispatch } from 'react-redux';
import { userActions } from '../store/slices/user-slice';
import { storeUserSession } from '../services/user-session-service';
import { AppDispatch } from '../store';
import { setSession } from '../store/actions/user-session';
import AppleAuthButton from '../components/AppleAuthButton';
import LoadingButton from '../components/LoadingButton';
import { reassignOrder } from '../services/orders-service';

const { width } = Dimensions.get('window');

interface SignInOptionsProps {
    navigation: any; // Update with your specific navigation type if using react-navigation typings
    refRBSheet: React.RefObject<any>; // Replace `any` with the specific type for your bottom sheet ref
    destination: string;
    orderId?: string; // Optional orderId prop
  }

const SignInOptions: React.FC<SignInOptionsProps> = ({
    navigation, 
    refRBSheet,
    destination,
    orderId,
}) => {
    const [signInWithEmail, setSignInWithEmail] = useState<boolean>(false);
    const [height, setHeight] = useState<number>(385)
    const dispatch: AppDispatch = useDispatch();

    const handleSignInWithEmail =  () => {
       setHeight(height + (signInWithEmail ? -150 : 150));
        setSignInWithEmail(!signInWithEmail);
    }

    const handleSignUpPress = () => {
        refRBSheet.current.close();
        navigation.navigate('CreateAccount', {formEmail: ''})
    }

    const handleSignInSubmit = async (email: string, password: string) => {
      const ipAddress = await getIpAddressFunc()
        
      const loginObj = createUserSession({
        email: email,
        password: password,
        IpAddress: ipAddress,
      });
  
      const resp = await loginUser(loginObj);
      const newSessionData = await validateUserToken();
      if (newSessionData) {
        await storeUserSession(resp);
        if(orderId){
            //try to re-assign the orderId to the new user if it exists
            await reassignOrder(orderId);
        }

        refRBSheet.current.close()
        dispatch(userActions.setUserObj({userObj: resp.user}));
        dispatch(setSession(newSessionData));
        navigation.navigate(destination, {myLocation: null});
      } 
    }

    return (
        <SafeAreaView>
            <RBSheet
                    ref={refRBSheet}
                    height={height}
                    duration={250}
                    closeOnDragDown={true}
                    closeOnPressMask={true}
                    customStyles={{
                        container: {
                        borderRadius: 20,
                        backgroundColor: "#1A1D21",
                        width: width <= 430 ? width*0.98 : 420,
                        height: height,
                        position: "relative",
                        display: "flex",
                        alignSelf: "center",
                        paddingBottom: 70,
                        marginBottom:0,
                        },
                    }}
                >   
                    <View>
                        <View style={{ flex:1, alignItems: 'center' }}>
                            <Image 
                                source={require('../../assets/headerBarIcon.png')} 
                                style={{ width: 55, height: 5, marginTop: 12 }}
                            />
                        </View>
                        <View className='p-4'>
                            <GoogleAuthButton 
                                navigation={navigation}
                                RBRef = {refRBSheet}
                                destination={destination}
                            />
                            <AppleAuthButton />

                            <Text className='my-4 text-lg font-normal text-center' style={styles.textColor}>or</Text>

                            <Pressable style={styles.bgColor} className='p-4 rounded-full'
                                onPress={handleSignInWithEmail}
                            >
                                <Text style={styles.textColorWhite} className='text-lg font-medium text-center text-white'>
                                    Sign in with email
                                </Text>
                            </Pressable>
                            
                            {signInWithEmail && 
                                <View style={{ width: '100%', height: 260}}>
                                    <SignInFieldsOnly
                                        onSubmit={handleSignInSubmit}
                                    />
                                </View>
                            }

                            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: 10 }} className='flex-row justify-center gap-3 mt-2'>
                                <Text style={styles.textColor} className='text-sm font-normal'>
                                    Don’t have an account? 
                                </Text>
                                <Text style={styles.textColor1} className='text-sm font-bold'
                                    onPress={handleSignUpPress}
                                >
                                    Sign up
                                </Text>
                            </View>
                        </View>
                    </View>
                </RBSheet>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    containerBgColor: {
        backgroundColor: '#1A1D21',
    },
    textColor: {
        color: '#8D8E90'
    },
    textColor1: {
        color: '#39BEB7',
    },
    textColorWhite: {
        color: 'white',
    },
    bgColor: {
        backgroundColor: '#39BEB7',
    }
});

export default SignInOptions;
