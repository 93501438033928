import { useEffect, useRef, useState, useCallback } from "react";
import {
  SafeAreaView,
  View,
  Text,
  Image,
  Dimensions,
  StyleSheet,
  Modal,
  ScrollView,
  Pressable,
  Animated
} from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import activelyChargingIcon from "../../assets/activelyChargingIcon.png";
import connectedNotCharging from "../../assets/connectedNotCharging.png";
import notConnectedOrCharging from "../../assets/notConnectedOrCharging.png";
import {
  checkinCharger,
  getActiveChargeData,
} from "../services/charger-service";
import { notificationService } from "../services/notification-service";
import { updateSelectedCharger } from "../store/actions/charging-actions";
import { ActiveChargeStatus, ChargerAttributes } from "../models/charger-model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { getCurrentUtcSeconds, secondsToHHMMSS } from "../components/common/utils/date-time-conversions";
import { checkIsPeak } from "../components/common/utils/peak-off-peak-field";
import { AppDispatch } from "../store";
import { getActiveCharges } from "../store/actions/user-actions";
import { ActiveChargeAttributes } from "../models/active-charges-model";
import { isSuccessful } from "../services/api-requests";
import LoadingButton from "../components/LoadingButton";

const { width, height } = Dimensions.get("window");

const ChargingState = {
  CONNECTED_AND_CHARGING: 0,
  CONNECTED_AND_NOT_CHARGING: 1,
  DISCONNECTED: 3,
  UNKNOWN: -1,
};

export default function ChargingScreen(props: { navigation: any; route: any }) {
  const [timeElapsed, setTimeElapsed] = useState<number>(0);
  const [localTimer, setLocalTimer] = useState<number>(0);
  const [kWhCharged, setKWhCharged] = useState<number>(0);
  const [kwhDisplayValue, setKwhDisplayValue] = useState(kWhCharged);
  const [estPrice, setEstPrice] = useState<number>(0);
  const [chargingStatus, setChargingStatus] = useState(ChargingState.UNKNOWN);
  const [activeChargeStatus, setActiveChargeStatus] = useState<ActiveChargeStatus>();
  const dispatch: AppDispatch = useDispatch();

  const pollTimer = useRef<NodeJS.Timeout | null>(null);
  let timer: NodeJS.Timeout | undefined;

  const selectedCharger: ChargerAttributes = useSelector(
    (state: RootState) => state.chargers.selectedCharger
  ) as ChargerAttributes;

  const activeCharges: ActiveChargeAttributes[] | null = useSelector(
    (state: RootState) => state.user.activeCharges
  );
  const [localActiveCharge, setLocalActiveCharge] =
    useState<ActiveChargeAttributes | null>(null);

  const startTime = useRef<Date>(new Date()); //useRef to persist value across renders
  const colorAnimation = useRef(new Animated.Value(0)).current;
  const pulseRef = useRef<Animated.CompositeAnimation | null>(null);
  const fadeAnimation = useRef(new Animated.Value(1)).current; // Animation for fade effect

  useEffect(() => {
    if (chargingStatus === ChargingState.CONNECTED_AND_CHARGING) {
      // Start the pulsing animation
      pulseRef.current = Animated.loop(
        Animated.sequence([
          Animated.timing(colorAnimation, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: false,
          }),
          Animated.timing(colorAnimation, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: false,
          }),
        ])
      );
      pulseRef.current.start();
    } else {
      // Stop the pulsing animation
      if (pulseRef.current) {
        pulseRef.current.stop();
        colorAnimation.setValue(0); // Reset color to initial state
      }
    }

    // Cleanup on unmount
    return () => {
      if (pulseRef.current) {
        pulseRef.current.stop();
      }
    };
  }, [colorAnimation, chargingStatus]);

  const interpolatedColor = colorAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ["#FFFFFF", "#39BEB7"],
  });

  useEffect(() => {
    // Start fade-out animation
    Animated.timing(fadeAnimation, {
      toValue: 0, // Fade out to 0
      duration: 500, // Duration of fade-out
      useNativeDriver: true,
    }).start(() => {
      // After fade-out completes, update the display value and fade back in
      setKwhDisplayValue(kWhCharged);
      Animated.timing(fadeAnimation, {
        toValue: 1, // Fade in to 1
        duration: 500, // Duration of fade-in
        useNativeDriver: true,
      }).start();
    });
  }, [kWhCharged]); // Run this effect when kWhCharged changes

  const pollChargingStatus = async (orderId: string) => {
    return await getActiveChargeData(orderId);
  };

  const handleChargingDone = async (orderId: string) => {
    try {
      const response = await checkinCharger(orderId);
      setLocalActiveCharge(null);
      if (isSuccessful(response.status)) {
        notificationService.sendNotification(
          "success",
          "Charger successfully checked in!"
        );
        updateSelectedChargerAndNavigateToSummary(orderId)
      } else {
        notificationService.sendNotification(
            "error",
            "Error checking in your charger!"
          );
      }
    } catch (e) {
      notificationService.sendNotification(
        "error",
        "Error checking in your charger!"
      );
      //TODO: need to figure out if charger is still attached and charging here,
      // ideally we should just try to shut down the charger from here and keep going
      console.error(e);
    }
    if (pollTimer.current) {
      clearInterval(pollTimer.current);
    }

    if (timer) {
      clearInterval(timer);
    }
  };

  const updateSelectedChargerAndNavigateToSummary = (orderId: string) => {
    if (localActiveCharge?.charger.uuid) {
      dispatch(updateSelectedCharger(localActiveCharge.charger.uuid));
    }
    //TODO: should be a summary screen of the total charge
    const endTime = new Date();
    props.navigation.navigate("ChargeSummary", {
      charger: localActiveCharge!.charger,
      startTime: startTime.current,
      endTime: endTime,
      orderId: orderId,
    });
  }

  // Poll function for charger status
  const pollAndUpdate = async () => {
    let activeChargeResponse;
    if (localActiveCharge) {
      activeChargeResponse = await pollChargingStatus(localActiveCharge.order.id);
      if (isSuccessful(activeChargeResponse.status)) {
        setActiveChargeStatus(activeChargeResponse.payload!);
        // Check if the message indicates completion
        if (activeChargeResponse?.payload?.message && activeChargeResponse.payload?.message === "complete") {
          updateSelectedChargerAndNavigateToSummary(localActiveCharge.order.id); // Call handleChargingDone with the order id
          return; // Exit the function to stop further checks if charging is done
        }
  
        if (
          activeChargeResponse.payload?.chargerCarData?.vehicle_charging === true &&
          activeChargeResponse.payload?.chargerCarData?.vehicle_connected === true
        ) {
          setChargingStatus(ChargingState.CONNECTED_AND_CHARGING);
        } else if (activeChargeResponse.payload?.chargerCarData.vehicle_connected === true) {
          setChargingStatus(ChargingState.CONNECTED_AND_NOT_CHARGING);
        } else {
          setChargingStatus(ChargingState.DISCONNECTED);
        }
      } else {
        setChargingStatus(ChargingState.UNKNOWN)
      }


    } else {
      setChargingStatus(ChargingState.UNKNOWN)
    }
  };

  useEffect(() => {
    // fetch the active charges
    dispatch(getActiveCharges());
  }, []);

  useEffect(() => {
    const startPolling = () => {
      pollAndUpdate();
      pollTimer.current = setInterval(pollAndUpdate, 5000); //set the polling interval
      // pollTimer = setInterval(async () => {
      //     pollAndUpdate()
      // }, 5000)
    };

    const stopPolling = () => {
      if (pollTimer.current) {
        clearInterval(pollTimer.current); // Clear the interval
        pollTimer.current = null;
      }
    };

    startPolling(); //only run on component mount

    return () => stopPolling(); //cleanup when component unmounts
  }, [localActiveCharge]);

  useEffect(() => {
    if (activeCharges && activeCharges.length > 0) {
      //console.log("orderId is:", props.route.params.order.id);
      const activeCharge = activeCharges?.find(
        (charge) => charge.order.id === props.route.params.order.id
      );
      console.log("got active charge:", activeCharge);
      if (activeCharge) {
        //console.log("setting active charge:", activeCharge);
        setLocalActiveCharge(activeCharge);
      } else {
        notificationService.sendNotification(
          "error",
          "Unable to locate your charge"
        );
      }
    } else {
      setLocalActiveCharge(null);
    }
  }, [activeCharges]);

  // Handle polling based on focus state
  useFocusEffect(
    useCallback(() => {
      return () => {
        if (pollTimer.current) {
          clearInterval(pollTimer.current); // Clear interval when screen loses focus
        }
        //update the application state if the charge is active
        dispatch(getActiveCharges());
      };
    }, [])
  );

  useEffect(() => {
    //the activeChargeStatus contains all of the connected and kwh data for the current charge
    if (activeChargeStatus && localActiveCharge) {
      setKWhCharged(activeChargeStatus.usageData.peakKwh + activeChargeStatus.usageData.offPeakKwh);
      setEstPrice(
        activeChargeStatus.peakPrice + activeChargeStatus.offPeakPrice
      );
      const serverTime =
        activeChargeStatus.usageData.peakTimeSeconds +
        activeChargeStatus.usageData.offPeakTimeSeconds;
      setTimeElapsed(serverTime);
      setLocalTimer(serverTime);
    }
  }, [activeChargeStatus]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    // Increment local timer every second
    if (chargingStatus === ChargingState.CONNECTED_AND_CHARGING) {
      interval = setInterval(() => {
        setLocalTimer((prevTimer) => prevTimer + 1);
        setKWhCharged((prevKWh) => prevKWh + ((((selectedCharger?.charging_capacity ?? 40) * 220) / 3600)/1000)); //charging
      }, 1000);
    }
    
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [chargingStatus]);

  let status = "Connected and charging";
  let image;

  switch (chargingStatus) {
    case ChargingState.CONNECTED_AND_CHARGING:
      status = "Connected and charging";
      image = activelyChargingIcon;
      break;
    case ChargingState.CONNECTED_AND_NOT_CHARGING:
      status = "Connected and not charging";
      image = connectedNotCharging;
      break;
    case ChargingState.DISCONNECTED:
      status = "Not connected to charger";
      image = notConnectedOrCharging;
      break;
    default:
      status = "Unknown status";
      break;
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <View
        style={[
          styles.containerBgColor,
          { height: height - 65, paddingBottom: 10, flex: 1 },
        ]}
      >
        <ScrollView>
          <View>
            <View className="relative">
              <Image
                source={require("../../assets/ActiveChargingBackground.jpg")}
                style={{ aspectRatio: 1 }}
                alt="Background Image"
              />
              <View
                style={{
                  position: "absolute",
                  bottom: 50,
                  left: "50%",
                  transform: "translateX(-50%)", // Centering horizontally
                  width: 227,
                  height: 74,
                }}
              >
                <Image
                  source={require("../../assets/CarStatic.png")}
                  style={{ width: 227, height: 74 }}
                  alt="car"
                />
                <View className="flex flex-row items-center gap-3">
                  <Image
                    source={require("../../assets/ChargeLocation.png")}
                    style={{ width: 40, height: 40 }}
                    alt="Location"
                  />
                  <View>
                    <Text className="text-base font-bold text-white">{`${localActiveCharge?.charger.name}`}</Text>
                    <Text className="text-sm font-semibold text-white">{`${localActiveCharge?.charger.city}, ${localActiveCharge?.charger.state}`}</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  position: "relative",
                  flex: 1,
                  alignContent: "center",
                  alignItems: "center",
                  transform: "translateY(85%)", // Centering horizontally
                  width: "auto",
                  height: 74,
                }}
              >
                <Text className="text-sm font-semibold text-white">
                  {`NOTE: Figures are approximate until charge is verified`}
                </Text>
              </View>
            </View>

            <View className="p-4">
              <View className="relative mt-4">
                <Image
                  source={require("../../assets/chargingHistoryBgImage.png")}
                  style={{
                    height: width <= 430 ? width * 0.64 : 270,
                  }}
                  resizeMode="cover"
                />
                <View
                  className="absolute flex flex-row items-center w-full"
                  style={{ height: width <= 430 ? width * 0.35 : 150 }}
                >
                  <View className="items-center flex-1 w-50">
                    <Image
                      source={image}
                      style={{
                        height: 75,
                        width: 75,
                      }}
                    />
                    <Text className="text-xs font-normal text-white">
                      {status}
                    </Text>
                  </View>
                  <View className="items-center flex-1 w-50">
                    <View className="relative">
                      <Image
                        source={require("../../assets/timeElapsedIcon.png")}
                        style={{
                          height: 80,
                          width: 80,
                        }}
                      />
                      <Text
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        className="absolute text-sm font-bold text-white"
                      >
                        {secondsToHHMMSS(localTimer)}
                        {/* {Math.floor(timeElapsed / 60)}:
                        {("0" + (timeElapsed % 60)).slice(-2)} */}
                      </Text>
                    </View>
                    <Text className="text-xs font-normal text-white">
                      Time elapsed
                    </Text>
                  </View>
                </View>
                <View
                  className="absolute flex flex-row items-center w-full"
                  style={{
                    height: width <= 430 ? width * 0.33 : 140,
                    marginTop: width <= 430 ? width * 0.33 : 140,
                  }}
                >
                  <View className="items-center flex-1 w-50">
                    <View className="flex flex-row items-center gap-3">
                      <Image
                        source={require("../../assets/totalKwhCharged.png")}
                        style={{
                          height: 60,
                          width: 60,
                        }}
                      />
                      <View>
                        <Animated.Text
                          style={{
                            color: interpolatedColor,
                          }}
                          className="text-2xl font-bold"
                        >
                          {kwhDisplayValue.toFixed(3)}
                        </Animated.Text>
                        <Text className="text-xs font-medium text-white">
                          kWh
                        </Text>
                      </View>
                    </View>
                    <Text className="mt-3 text-xs font-normal text-white">
                      Total charged
                    </Text>
                  </View>
                  <View className="items-center flex-1 w-50">
                    <View className="relative">
                      <Image
                        source={require("../../assets/AccruedCostBackground.png")}
                        style={{
                          height: 75,
                          width: 110,
                        }}
                      />
                      
                      <Animated.Text
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          // fontSize: 24, // equivalent to text-3xl
                          // fontWeight: "500", // equivalent to font-medium
                          color: interpolatedColor,
                        }}
                        className="absolute text-3xl font-medium"
                      >
                        {`$${estPrice.toFixed(2)}`}
                      </Animated.Text>
                    </View>
                    <Text className="text-xs font-normal text-white">
                      Est. cost
                    </Text>
                  </View>
                </View>
              </View>

              <View>
                <Text
                  className="mt-4 font-medium text-center text-white"
                  style={{ fontSize: 10 }}
                >
                  To stop charging, unplug the car at any time or press the
                  button below.
                </Text>
              </View>

              <LoadingButton
                onPress={async () => {
                  // Simulate a network request
                  await handleChargingDone(props.route.params.order.id)
                }}
                disabled={false}
                backgroundColor={styles.BgColor.backgroundColor} // Optional custom color
              >
                Stop Charging
              </LoadingButton>
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#0B1E1D",
  },
  containerBgColor: {
    backgroundColor: "#0B1E1D",
  },
  BgColor: {
    backgroundColor: "#F75555",
  },
  BgColor1: {
    backgroundColor: "#39BEB780",
  },
  BgColor2: {
    backgroundColor: "#F97777",
  },
  BgColor3: {
    backgroundColor: "#39BEB7",
  },
  TextColor1: {
    color: "#E8E8E9",
  },
  textColor2: {
    color: "#A3A5A6",
  },
  textColor3: {
    color: "#8D8E90",
  },
  line: {
    borderBottomColor: "#636363",
    borderBottomWidth: 1,
    borderStyle: "dashed",
    marginVertical: 20,
  },
});
