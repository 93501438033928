//Get directions button, specifically for web, since direct linking does not work on mobile
import React from "react";
import {
  Text,
  Pressable,
  Platform,
  Linking,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";
import { ChargerAttributes } from "../models/charger-model";

interface DirectionsButtonProps {
    selectedCharger: ChargerAttributes;
    buttonStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    webClassName?: string; // Optional classname for web styles
  }

  const DirectionsButton: React.FC<DirectionsButtonProps> = ({
    selectedCharger,
    buttonStyle,
    textStyle,
    webClassName,
  }) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      `${selectedCharger.full_address}, ${selectedCharger.zip_code}`
    )}&travelmode=driving`;
  
    if (Platform.OS === 'web') {
      // Use <a> for web browsers
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ 
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            textDecoration: 'none',
            border: (buttonStyle as React.CSSProperties)?.borderWidth ? "2px solid #8D8E90" : "",
            borderRadius: 30,
            backgroundColor: (buttonStyle as React.CSSProperties)?.backgroundColor || 'transparent',
            padding: "3px 6px"
          }}
        >
          <Text className={webClassName} style={textStyle}>
            Get Directions
          </Text>
        </a>
      );
    }
  
    // Use Pressable for React Native
    return (
        <Pressable
            onPress={() => {
                Linking.openURL(url).catch(err =>
                    console.error('Failed to open URL:', err)
                );
            }}
            style={buttonStyle}
        >
        <Text style={textStyle}>Get Directions</Text>
      </Pressable>
    );
  };

  export default DirectionsButton;