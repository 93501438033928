import React, {SetStateAction, useEffect, useState} from "react";
import {
    SafeAreaView,
    View,
    Text,
    StyleSheet,
    Pressable
  } from "react-native";
import { FontAwesome6 } from '@expo/vector-icons';
import { WeekConfiguration, createEmptyWeek} from "../components/common/utils/peak-off-peak-field";
import DropdownComponentPeak from "../components/DropdownComponentPeak";
import { notificationService } from "../services/notification-service";

type TimeErrors = {
    [day: string]: { 
        start?: string; 
        end?: string
    };
};


const RegisterChargerCheckbox = (props: 
    {
        setPeakSelection: (peakSelection: WeekConfiguration) => void,
        checkboxSelected: {
                updatedDay: string[],
                startEndTime: {}[]
            }, 
        generatePeakObjCheckbox: any, 
        editChargerStatus: boolean,
        selectionErrors: (isError: boolean) => void,
    }) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [weekView, setWeekView] = useState<WeekConfiguration>(createEmptyWeek());
    const [errors, setErrors] = useState<TimeErrors>({});; // Error state
    
    const handleOptionSelect = (day: string) => {
        const index = selectedOptions.indexOf(day);

        if (index === -1) {
            setSelectedOptions([...selectedOptions, day]);
        } else {
            const updatedOptions = [...selectedOptions];
            updatedOptions.splice(index, 1);
            setSelectedOptions(updatedOptions);
        }

        if(day && index !== -1){
            setWeekView({
                ...weekView,
                [day]: {
                    start: 0,
                    end: 0
                }
            })
        }
    };

    useEffect(()=> {
        
        if(props.editChargerStatus === true){
            setWeekView(props.generatePeakObjCheckbox)
            
            if(props.checkboxSelected?.updatedDay){
                setSelectedOptions(props.checkboxSelected?.updatedDay);
            }
        }

    }, [props.generatePeakObjCheckbox, props.checkboxSelected, props.editChargerStatus])

    useEffect(() => {
        props.setPeakSelection(weekView);
    },[selectedOptions, weekView])

    const handleTimeSelect = (sel: any, day: string, type: 'start' | 'end') => {
        const newTime = sel.value;
        const currentDayConfig = weekView[day];

        const newConfig = {
            start: type === 'start' ? newTime : currentDayConfig.start,
            end: type === 'end' ? newTime : currentDayConfig.end,
        };

        // Validation: Check if end is before start
        if (newConfig.end < newConfig.start) {
            notificationService.sendNotification('error', 'End time cannot be before start time');
            setErrors((prevErrors) => ({
                ...prevErrors,
                [day]: {
                    ...prevErrors[day], // Preserve existing errors for this day
                    [type]: 'End time cannot be before start time',
                },
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [day]: {
                    ...prevErrors[day],
                    start: undefined, // Clear the error for this type
                    end: undefined,
                },
            }));
        }
    
        setWeekView((prevWeekView) => ({
            ...prevWeekView,
            [day]: newConfig,
        }));
    }

    useEffect(() => {
        const hasErrors = Object.values(errors).some((dayErrors) =>
            Object.values(dayErrors || {}).some((error) => error !== undefined)
        );

        props.selectionErrors(hasErrors);
    }, [errors])


    const options = [
        { value: 0, label: '12 AM' },
        { value: 1, label: '1 AM' },
        { value: 2, label: '2 AM' },
        { value: 3, label: '3 AM' },
        { value: 4, label: '4 AM' },
        { value: 5, label: '5 AM' },
        { value: 6, label: '6 AM' },
        { value: 7, label: '7 AM' },
        { value: 8, label: '8 AM' },
        { value: 9, label: '9 AM' },
        { value: 10, label: '10 AM' },
        { value: 11, label: '11 AM' },
        { value: 12, label: '12 PM' },
        { value: 13, label: '1 PM' },
        { value: 14, label: '2 PM' },
        { value: 15, label: '3 PM' },
        { value: 16, label: '4 PM' },
        { value: 17, label: '5 PM' },
        { value: 18, label: '6 PM' },
        { value: 19, label: '7 PM' },
        { value: 20, label: '8 PM' },
        { value: 21, label: '9 PM' },
        { value: 22, label: '10 PM' },
        { value: 23, label: '11 PM' },
    ];

    const startStopSelection = Object.entries(weekView).map(([day, config], index) => {
        const dayErrors = errors[day] || {};
        return(
            <View 
                key={`${day}StartStop`} 
                style={styles.row}
                className="mt-2"
            >
                <View key={day} style={styles.row} className="w-32 h-10">
                    <Pressable 
                        onPress={() => handleOptionSelect(day)}
                    >
                        <View style={styles.checkbox}>
                            {
                                selectedOptions.find(d => d === day) ? 
                                <View>                                    
                                    <FontAwesome6 name="check" size={17} color="white" />
                                </View>
                                : <View></View>
                            }
                        </View>
                    </Pressable>
                    <Text className="text-base text-white">{day}</Text>
                </View>

                <DropdownComponentPeak
                    key={`${day}Start`}
                    data={options}
                    placeholderText={'start time'}
                    onItemSelected={(s) => handleTimeSelect(s, `${day}`, 'start')}
                    initVal={options[config.start].value}
                    hidden={selectedOptions.find(d => d === day) ? false : true}
                    error={!!dayErrors.start} // Error for start
                    type="start"
                />

                <DropdownComponentPeak
                    key={`${day}Stop`}
                    data={options}
                    placeholderText={'end time'}
                    onItemSelected={(value) => handleTimeSelect(value, `${day}`, 'end')}
                    initVal={options[config.end].value}
                    hidden={selectedOptions.find(d => d === day) ? false : true}
                    error={!!dayErrors.end} // Error for end
                    type="end"
                />
            </View>
        )
    });

    return(
        <View>
            <View className="flex flex-row">
                <View>
                    {startStopSelection}
                </View> 
            </View>
        </View>
        
    );
}

export default RegisterChargerCheckbox;

const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        backgroundColor: '#0B1E1D',
    },
    containerBgColor: {
        backgroundColor: '#0B1E1D',
    },
    BgColor1: {
        backgroundColor: '#292D33'
    },
    BgColor2: {
        backgroundColor: '#234D4E'
    },
    line: {
        borderBottomColor: "#636363",
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        marginVertical: 20,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2
    },
    checkbox: {
        width: 20,
        height: 20,
        borderWidth: 1,
        borderColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        borderRadius: 5,
    },
});