import { Pressable, Text, View } from "react-native";
import LoadingButton from "../LoadingButton";

const StripeSubmitButton = (
  props = {
    onSubmit: () => Promise<void>,
  }
) => {
  return (
    <LoadingButton
      onPress={async () => {
        await props.onSubmit(); // Ensure this function returns a promise
      }}
      //disabled
    >
      Submit
    </LoadingButton>
  );

};

export default StripeSubmitButton;
