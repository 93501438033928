import React, { useCallback, useMemo, useState } from 'react';

import { SafeAreaView, 
          View,
          Text, 
          ScrollView, 
          StyleSheet, 
          Dimensions, 
          TextInput, 
          Image, 
          Pressable, 
          KeyboardAvoidingView, 
          Platform, 
          ActivityIndicator 
        } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { FontAwesome6 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChargerAttributes } from '../models/charger-model';
import { RootState } from '../store';
import { getMyDrivingTime } from '../store/actions/charging-actions';
import { chargerActions } from '../store/slices/charger-slice';
import { Button } from 'react-native-paper';
import { AppDispatch } from '../store';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../components/MeanderStack';
import * as Location from 'expo-location';
import { notificationService } from '../services/notification-service';

interface SearchChargerProps {
    navigation: NativeStackNavigationProp<RootStackParamList, 'SearchCharger'>;
    myLocation: Location.LocationObjectCoords;
    chargersWithDistance: ChargerAttributes[];
  }

const SearchCharger: React.FC<SearchChargerProps>  = ({ navigation, myLocation, chargersWithDistance }) => {
    const { height } = Dimensions.get('window');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;
    const [query, setQuery] = useState('');

    const [loading, setLoading] = useState(false);
    const [selectedChargerId, setSelectedChargerId] = useState<string | null>(null);

    const [uuidInfo, setUuid] = useState<String>('');

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handleSearchQuery = useCallback((searchTerm: string) => {
        setQuery(searchTerm);
      }, []);

    // Memoize filtered chargers based on the query
    const searchFilteredChargers = useMemo(() => {
        return chargersWithDistance.filter((charger) => {
            return (
                charger.unit_serial.toLowerCase().includes(query.toLowerCase()) ||
                charger.name.toLowerCase().includes(query.toLowerCase()) ||
                charger.city.toLowerCase().includes(query.toLowerCase())
            );
        });
    }, [chargersWithDistance, query]);

    // Calculate the paginated data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchFilteredChargers.slice(indexOfFirstItem, indexOfLastItem);

    const dispatch: AppDispatch = useDispatch();

    const handleSelectCharger = async (charger: any) => {
        try{
          setLoading(true);
          setSelectedChargerId(charger.uuid);

          const myTime = await getMyDrivingTime(myLocation, charger.uuid);

          dispatch(chargerActions.updateSelectedCharger({selectedCharger: charger}));
          navigation.navigate("ChargerStation", {
              myDistanceFromCharger: charger.totalDistance,
              myTimeFromCharger: myTime,
          });
        } catch (error) {
          notificationService.sendNotification('error', "Failed to select the charger. Please try again");
          console.error("Error selecting charger:", error);
        } finally {
          setLoading(false);
          setSelectedChargerId(null);
        }
        
        
    }

    return (
      <SafeAreaView style={styles.safeArea}>
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        >
          <View style={[styles.containerBgColor, { flex: 1 }]}>
            {/* Fixed Search Bar */}
            <View style={{ padding: 16 }}>
              <View style={styles.searchContainer}>
                <Ionicons name="search" size={22} color="white" style={styles.searchIcon} />
                <TextInput
                  placeholder="Search charger by name, city, or serial #"
                  style={styles.input}
                  placeholderTextColor="#a0aec0"
                  value={query}
                  onChangeText={handleSearchQuery}
                />
                <Pressable onPress={() => handleSearchQuery('')}>
                  <MaterialIcons name="highlight-remove" size={38} color="black" style={styles.clearIcon} />
                </Pressable>
              </View>
            </View>
    
            {/* Scrollable Card Area */}
            <View style={{ flex: 1 }}>
              <ScrollView
                contentContainerStyle={{ paddingBottom: 10 }}
                showsVerticalScrollIndicator={true}
                keyboardShouldPersistTaps="handled"
              >
                {currentItems?.map((data, index) => {
                  const isSelected = selectedChargerId === data.uuid;

                  return(
                    <View
                      className={`mt-4 rounded-lg ${loading && !isSelected ? "opacity-50" : ""}`}
                      key={index}
                      style={[styles.cardContainer, loading && !isSelected && styles.dimmed]}
                    >
                      <Pressable 
                        disabled={loading || isSelected}
                        onPress={() => handleSelectCharger(data)}
                        style={({ pressed }) => [
                          pressed && { opacity: 0.6 },
                          styles.pressable,
                        ]}
                      >
                        <View className="flex flex-row items-center justify-between p-3">
                          <View className="flex flex-row items-center gap-4">
                            <Image
                              source={require('../../assets/chargerStation.png')}
                              style={styles.chargerImage}
                            />
                            <View>
                              <Text className="text-base font-bold text-white" style={styles.alignEllipses}>
                                {data?.name ? data?.name : 'Unknown'}
                              </Text>
                              <Text style={[styles.textColor, styles.alignEllipses]} className="text-xs font-medium">
                                {`${data.full_address}`}
                              </Text>
                              <Text style={[styles.textColor, styles.alignEllipses]} className="text-xs font-medium">
                                {data?.description}
                              </Text>
                            </View>
                          </View>
                          <View className="flex flex-row items-center gap-1">
                            <FontAwesome6 name="location-dot" size={16} color="#8D8E90" />
                            <Text style={styles.textColor}>{data?.totalDistance} miles</Text>
                            <MaterialIcons name="keyboard-arrow-right" size={24} color="white" />
                          </View>
                        </View>
                        {/* Loading Spinner */}
                        {isSelected && loading && (
                          <View style={styles.loadingOverlay}>
                            <ActivityIndicator size="large" color="#ffffff" />
                          </View>
                        )}
      
                        {/* Charger Type and Status */}
                        <View className="flex flex-row items-center justify-between p-3">
                          <View className="flex flex-row items-center gap-3">
                            <Image
                              source={require('../../assets/chargerType.png')}
                              style={styles.chargerTypeImage}
                            />
                            <View>
                              <Text className="text-xs font-normal text-white" style={styles.textColor1}>
                                {`${data?.charger_model} - ${data?.charger_type} Charger`}
                              </Text>
                              <View className="flex flex-row items-center gap-1">
                                <Text className="text-lg font-semibold text-white">
                                  {`$ ${data?.price_kwh_peak}/kWh (pk)`}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View>
                            {data?.isOccupied === 0 ? (
                              <Text
                                style={styles.availableStatus}
                                className="w-full px-6 py-2 text-white rounded-full"
                                onPress={() => handleSelectCharger(data)}
                              >
                                Available
                              </Text>
                            ) : (
                              <Text style={styles.unavailableStatus} className="px-4 py-2 text-white rounded-full">
                                Unavailable
                              </Text>
                            )}
                          </View>
                        </View>
                      </Pressable>
                    </View>
                  );
                })}
              </ScrollView>
            </View>
    
            {/* Fixed Pagination */}
            <View style={styles.paginationContainer}>
              {Array.from({ length: Math.ceil(searchFilteredChargers.length / itemsPerPage) }).map((_, i) => (
                <Pressable key={i} onPress={() => handlePageChange(i + 1)}>
                  <Text style={currentPage === i + 1 ? styles.activePage : styles.inactivePage}>
                    {i + 1}
                  </Text>
                </Pressable>
              ))}
            </View>
          </View>
        </KeyboardAvoidingView>
      </SafeAreaView>
    );
  };
    
    const styles = StyleSheet.create({
      dimmed: {
        opacity: 0.5,
      },
      loadingOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1,
      },
      pressable: {
        position: "relative",
      },
      safeArea: {
        flex: 1,
        backgroundColor: '#1A1D21',
      },
      containerBgColor: {
        flex: 1,
        backgroundColor: '#1A1D21',
      },
      fixedSection: {
        // Use this for non-scrollable sections like search bar and pagination
        paddingHorizontal: 16,
        paddingVertical: 8,
      },
      searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 25,
        paddingHorizontal: 20,
      },
      searchIcon: {
        backgroundColor: '#39BEB7',
        borderRadius: 50,
        paddingVertical: 12,
        paddingHorizontal: 13,
        marginRight: 8,
      },
      scrollViewContent: {
        // Ensure the content can grow and scroll
        flexGrow: 1,
        paddingHorizontal: 16,
        paddingBottom: 16,
      },
      card: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      input: {
        flex: 1,
        paddingVertical: 10,
      },
      clearIcon: {
        marginLeft: 5,
        marginTop: 2,
      },
      cardContainer: {
        backgroundColor: '#292D33',
        marginVertical: 5,
        borderRadius: 10,
        padding: 5,
      },
      chargerImage: {
        width: 50,
        height: 50,
      },
      chargerTypeImage: {
        width: 33,
        height: 33,
      },
      alignEllipses: {
        fontWeight: '500',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 150,
      },
      textColor: {
        color: '#8D8E90',
      },
      textColor1: {
        color: '#E8E8E9',
      },
      availableStatus: {
        backgroundColor: '#51B868',
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 16,
        color: 'white',
      },
      unavailableStatus: {
        backgroundColor: '#F97777',
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 16,
        color: 'white',
      },
      paginationContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 5,
      },
      activePage: {
        color: 'white',
        padding: 5,
        backgroundColor: '#39BEB7',
        borderRadius: 5,
        marginHorizontal: 3,
      },
      inactivePage: {
        color: 'white',
        padding: 5,
        marginHorizontal: 3,
      },
    });

export default SearchCharger;